import React from 'react';
import { Nav,Container,Row,Col,Tab,Table,Card,Button,Collapse } from 'react-bootstrap';
import parse from 'html-react-parser';


class Ligne extends React.Component{
    constructor(props)
    {
      super(props);
     
    
    
     this.state = {
       
       data: props.data,
       index: props.index,
       open: false,
       color: '',
       index_col : this.props.index_tab
     }
    
    }
    componentDidUpdate(prevProps) {
      
     
    }
    clique_ligne(param,param2)
    {
      this.props.handler(param,param2,this.props.index_tab);
       this.props.handlers(param,param2,this.props.index_tab);
      
       
    }
    couleur_ligne()
    {
    
      if(parseInt(this.props.active) !== parseInt(this.props.index_tab))
      {
       
        return null;

      }
      else
      {
        
        return "#ddd";
      }
    }
    ligne_tableau()
    {
      const handleSelect = (eventKey) =>    console.log("clique sur tab");
        let ligne = [];
       if(this.props.col === "5")
       {
        ligne.push(<tr style={{backgroundColor: this.couleur_ligne(this.props.index_tab)}} onClick={() => this.clique_ligne(this.props.data[3],this.props.index_tab)}><td>{this.props.data[0]}</td><td>{this.props.data[1]}</td><td>{this.props.data[2]}</td><td>{this.props.data[3]}</td><td>{this.props.data[4]}</td></tr>);

       }
       else if(this.props.col === "4")
       {
          
         if(this.props.data[0].id !== undefined)
         {
          ligne.push(<tr style={{backgroundColor: this.couleur_ligne(this.props.index_tab)}} onClick={() => this.clique_ligne(this.props.data[0].id,this.props.index_tab)}><td>{this.props.data[0].dmsId}</td><td>{this.props.data[0].nomPatient}</td><td>{this.props.data[0].prenomPatient}</td><td>{this.props.data[0].portable}</td></tr>);

         }
         else
         {
          ligne.push(<tr style={{backgroundColor: this.couleur_ligne(this.props.index_tab)}} onClick={() => this.clique_ligne(this.props.data[3],this.props.index_tab)}><td>{this.props.data[0]}</td><td>{this.props.data[1]}</td><td>{this.props.data[2]}</td><td>{this.props.data[3]}</td></tr>);

         }

       }
       else
       {
        ligne.push(<tr style={{backgroundColor: this.couleur_ligne(this.props.index_tab)}} onClick={() => this.clique_ligne(this.props.data[3],this.props.index_tab)} ><td>{this.props.data[0]}</td><td>{this.props.data[1]}</td><td>{this.props.data[2]}</td></tr>);

       }
      
        

         return (
          ligne
         );
    }
    Example() {
      
    
      return (
        <>
          <Button
            onClick={() => this.setState({ open: true })}
            aria-controls="example-collapse-text"
            aria-expanded={this.state.open}
          >
            click
          </Button>
          <Collapse in={this.state.open}>
           <table></table>
          </Collapse>
        </>
      );
    }
    ligne_tableau_s()
    {
        let ligne = [];
        let str = [];
         for (let i = 0; i < this.state.data.length; i++) { 
             
             
            ligne.push(<tr><td>{this.Example()}</td><td>{this.state.data[i][0]}</td><td>{this.state.data[i][1]}</td></tr>);
  
 
         }
       
          return (
           ligne
          );
    }
    render()
    {
       if(this.props.type === '2')
         {
            return(this.ligne_tableau_s());
         }
         else
         {
            return (
                this.ligne_tableau()
              ); 
        }
     
      
    }
}
export default Ligne