import React from 'react';
import styles from '../../styles/css/adminlte.min.css'
 
 

export default function Menu(props) {
  return(
   
         <aside class="main-sidebar sidebar-dark-primary elevation-4">
         <a   class="brand-link" style={{textDecoration: 'none'}}>
         <img src={require('../../assets/logo.png').default}  class="brand-image img-circle elevation-3" />
         <span class="brand-text font-weight-light">Dental SMS</span>
         </a>
         <div class="sidebar">
        <div class="user-panel mt-3 pb-3 mb-3 d-flex"> 
        <div class="image">
            <img src={require('../../assets/doctor.png').default}  class="brand-image img-circle elevation-3" />
        </div>
        <div class="info">
            <a href="#" class="d-block" style={{textDecoration: 'none'}}>{props.name}</a>
        </div>
        </div>
        <nav class="mt-2">
          <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <li class="nav-item">
              <a href="/#config" class="nav-link">
                <i class="nav-icon fas fa-wrench"></i>
                <p>
                  Configuration
                </p>
              </a>
            </li>     
            <li class="nav-item">
              <a href="/#accueil" class="nav-link">
                <i class="nav-icon fas fa-envelope"></i>
                <p>
                  Activité
                </p>
              </a>
            </li>         
          </ul>
        </nav>
      </div>
         </aside>
       
  
  )
}

 