import React, {Fragment} from 'react';
import {Nav, Container, Row, Col, Tab, Table, Card, Button, Collapse, Form} from 'react-bootstrap';
import parse from 'html-react-parser';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Token from '../../utilities/Token';
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import {param} from 'jquery';
import {Popover} from 'react-tiny-popover';


class Detail_message extends React.Component {
    constructor(props) {
        super(props);
        this.state =
            {
                disabled: "disabled",
                texte: '',
                select_texte: '',
                code: '',
                nom: '',
                texte_msg: '',
                id_msg: '',
                index: '',
                code_message_class: '',
                popoverOpenClose: false
            }
        this.VisuText = this.VisuText.bind(this);
        this.texte = this.texte.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.set_texte = this.set_texte.bind(this);
        this.set_message = this.set_message.bind(this);
        this.update_text = this.update_text.bind(this);
        this.setIsPopoverOpen = this.setIsPopoverOpen.bind(this);
    }

    componentDidMount() {
        if (this.props.index !== "") {
            this.setState({
                code: this.props.data[this.props.index].codeMessage,
                nom: this.props.data[this.props.index].title
            }, function () {

            });
        }
    }

    set_texte(event) {
        console.log(event);
        console.log(event.target.selectionStart);
        this.setState({select_texte: event.target.selectionStart}, function () {
            this.forceUpdate();
            this.set_message("texte");


        });

    }

    insert = function (index, string) {
        if (index > 0) {
            return this.substring(0, index) + string + this.substr(index);
        }
    }

    texte(val, val1) {
        if (this.state.select_texte !== '') {
            console.log(val, val1); // val 1 = mot ajouté
            let text = this.state.texte_msg; // text avant ajout
            console.log("pos du curseur" + this.state.select_texte); // position curseur
            text = text.substring(0, this.state.select_texte) + val + " " + text.substr(this.state.select_texte)
            //   text = text.slice(0,this.state.select_texte) + val + text.slice(this.state.select_texte);
            console.log("après ajout" + text);
            let pos_after = this.state.select_texte + val.length + 1;
            this.setState({texte_msg: text, select_texte: pos_after}, function () {

                this.VisuText();
                this.set_message("texte");
                //  this.forceUpdate();


            });


        }

    }

    VisuText() {
        console.log(this.props);
        let text = this.state.texte_msg;
        text = text.replaceAll("[NOM_PATIENT]", "Louvet");
        text = text.replaceAll("[PRENOM_PATIENT]", "Mélanie");
        text = text.replaceAll("[DATE_NAISSANCE]", "05/08/2005");
        text = text.replaceAll("[NOM_PARENT]", "Louvet Alexandre");
        text = text.replaceAll("[NOM_DOCTEUR]", "Albert Molaire");
        text = text.replaceAll("[PRATICIEN]", "Albert Molaire");
        text = text.replaceAll("[DATE_ET_HEURE_RDV]", "27/12/2021 à 09:44");
        text = text.replaceAll("[CIVILITE_PATIENT]", "L'enfant");
        text = text.replaceAll("[NOM_RDV]", "Consultation");
        this.setState({texte: text}, function () {
            this.forceUpdate();
        });


    }

    validate_code(code) {
        console.log("ok")
        let array = [];
        if (parseInt(code) === this.props.data[this.props.index].codeMessage) {
            this.setState({code_message_class: "form-control is-valid"})
            return false;
        }
        Object.keys(this.props.data).forEach(key => {

            array.push(this.props.data[key].codeMessage);


        });

        if (array.indexOf(parseInt(code)) !== -1) {
            this.setState({code_message_class: "form-control is-invalid"})
            return true;
        } else {
            this.setState({code_message_class: "form-control is-valid"})
            return false;
        }


    }

    setIsPopoverOpen(openClose) {
        if (openClose == true) {
            this.setState({popoverOpenClose: true})
        } else {
            this.setState({popoverOpenClose: false})
        }
    }

    set_message(params) {
console.log('set_message')
        var time = new Date().toUTCString();
        var id_cabinet = localStorage.getItem('id_cabinet');
        var cabinet = localStorage.getItem('cabinet');
        var token = localStorage.getItem('token');

        var result = false;
        if (params === "code") {
            var params = {
                code_message: parseInt(this.state.code),
                id: this.state.id_msg
            };
            result = this.validate_code(this.state.code);
        } else if (params === "nom") {
            var params = {
                title: this.state.nom,
                id: this.state.id_msg
            };
        } else {
            console.log(this.state.texte_msg);
            var params = {
                texte: this.state.texte_msg,
                id: this.state.id_msg
            };
        }


        if (result) {

        } else {

            var tokens = Token.calcul_token("PUT", params, token, cabinet, '/message/' + localStorage.getItem('id_cabinet'));
            let optionurl = "/message/" + localStorage.getItem('id_cabinet');
            if (window.location.href.indexOf('localhost') > -1)

                var baseURL = 'http://localhost:8844' + optionurl;

            else if (window.location.href.indexOf('-dev') > -1)

                var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

            else

                var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;


            //const baseURL = "http://localhost:8844/message/"+localStorage.getItem('id_cabinet');

            axios.put(baseURL, params,
                {
                    headers: {
                        'X-AUTH-TOKEN': tokens,
                        'Date-request': time,
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {

                    toast.success('Modification effectuée avec succès', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.props.msg();

                })
                .catch(error => {
                    console.log(error.response.status)
                    toast.error('Problème de connexion', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }


    }

    handleChange(event) {
        this.setState({texte_msg: event.target.value}, function () {
            this.VisuText();
            this.forceUpdate();
        });

    }

    componentWillReceiveProps(nextProps) {
        console.log('componentWillReceiveProps', this.props);

        if (this.props.data[this.props.index] !== undefined && this.props.index !== "") {

            console.log(this.props.index);
            this.setState({
                code: this.props.data[this.props.index].codeMessage,
                nom: this.props.data[this.props.index].title,
                id_msg: this.props.data[this.props.index].id,
                texte_msg: this.props.data[this.props.index].texte,
                index: this.props.index,
                code_message_class: ''
            }, function () {
                this.VisuText();
                /*   this.forceUpdate();
                   console.log(this.state.texte_msg);*/
            });

        }

    }

    update_text() {

    }

    nombre_sms_estimer() {
        if (this.state.texte.length > 0 && this.state.texte.length < 160) {
            return 1;
        } else {

            let estimation = this.state.texte.length / 160;
            console.log(' Math.ceil(estimation)')
            console.log(Math.ceil(estimation));
            if (Math.ceil(estimation) >= 2) {
                let nbSms = Math.ceil(estimation)
                console.log('nbSms')
                console.log(nbSms);
                let nbCaractereAEnlever = nbSms * 7
                console.log('nbCaractereAEnlever')
                console.log(nbCaractereAEnlever);
                let nbCaractereAvecLes7 = this.state.texte.length + nbCaractereAEnlever
                console.log('nbCaractereAvecLes7')
                console.log(nbCaractereAvecLes7);
                let nbSmsCalcul = nbCaractereAvecLes7 / 160;
                console.log('Math.ceil(nbSmsCalcul)')
                console.log(Math.ceil(nbSmsCalcul));
                estimation = Math.ceil(nbSmsCalcul);
            } else {
                estimation = Math.ceil(estimation);
            }
            return Math.ceil(estimation);
        }

    }

    affichage() {
        if (this.props.index === "") {

            return (<Col><Card>
                    <Card.Header>Détail texte SMS</Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Code :</Form.Label>
                                <Form.Control type="number" placeholder="" disabled/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Nom :</Form.Label>
                                <Form.Control type="text" placeholder="" disabled/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Texte SMS :</Form.Label>
                                <Form.Control as="textarea" rows={4} disabled/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Button variant="primary" size="sm">Nom patient</Button>{' '}
                                <Button variant="primary" size="sm">Prénom patient</Button>{' '}
                                <Button variant="primary" size="sm">Date de naissance</Button>{' '}
                                <Button variant="primary" size="sm">Nom parent</Button>{' '}
                                <Button variant="primary" size="sm">Nom docteur</Button>{' '}
                                <Button variant="primary" size="sm">Praticien</Button>{' '}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Button variant="primary" size="sm">Date/Heure RDV</Button>{' '}
                                <Button variant="primary" size="sm">Civilité patient</Button>{' '}
                                <Button variant="primary" size="sm">Nom RDV</Button>{' '}
                            </Form.Group>
                        </Form>

                    </Card.Body>
                </Card>
                    <Card>
                        <Card.Header>Aperçu SMS :</Card.Header>
                        <Card.Body>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Aperçu SMS :</Form.Label>
                                <Form.Control as="textarea" rows={4} disabled/>
                            </Form.Group>
                        </Card.Body>
                    </Card></Col>
            );
        } else {

            return (<Col><Card>
                    <Card.Header>Détail texte SMS</Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Code :</Form.Label>
                                <Form.Control type="number" className={this.state.code_message_class}
                                              onBlur={this.set_message.bind(this, "code")} value={this.state.code}
                                              onChange={e => this.setState({code: e.target.value})}/>
                                <Form.Control.Feedback type="invalid">
                                    Ce code existe déjà, merci de choisir un code non utilisé.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Nom :</Form.Label>
                                <Form.Control type="text" maxLength="50" onBlur={this.set_message.bind(this, "nom")}
                                              value={this.state.nom}
                                              onChange={e => this.setState({nom: e.target.value})}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Texte SMS :</Form.Label>
                                <Form.Control as="textarea" maxLength="1000" rows={5} onBlur={this.set_texte}
                                              onChange={this.handleChange} value={this.state.texte_msg}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Button variant="primary" size="sm" onClick={this.texte.bind(this, "[NOM_PATIENT]")}>Nom
                                    patient</Button>{' '}
                                <Button variant="primary" size="sm" onClick={this.texte.bind(this, "[PRENOM_PATIENT]")}>Prénom
                                    patient</Button>{' '}
                                <Button variant="primary" size="sm" onClick={this.texte.bind(this, "[DATE_NAISSANCE]")}>Date
                                    de naissance</Button>{' '}
                                <Button variant="primary" size="sm" onClick={this.texte.bind(this, "[NOM_PARENT]")}>Nom
                                    parent</Button>{' '}
                                <Button variant="primary" size="sm" onClick={this.texte.bind(this, "[NOM_DOCTEUR]")}>Nom
                                    docteur</Button>{' '}
                                <Button variant="primary" size="sm"
                                        onClick={this.texte.bind(this, "[PRATICIEN]")}>Praticien</Button>{' '}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Button variant="primary" size="sm"
                                        onClick={this.texte.bind(this, "[DATE_ET_HEURE_RDV]")}>Date/Heure
                                    RDV</Button>{' '}
                                <Button variant="primary" size="sm"
                                        onClick={this.texte.bind(this, "[CIVILITE_PATIENT]")}>Civilité
                                    patient</Button>{' '}
                                <Button variant="primary" size="sm" onClick={this.texte.bind(this, "[NOM_RDV]")}>Nom
                                    RDV</Button>{' '}
                            </Form.Group>
                            <ToastContainer/>
                        </Form>

                    </Card.Body>
                </Card>
                    <Card>
                        <Card.Header>Aperçu SMS</Card.Header>
                        <Card.Body>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control as="textarea" rows={5} defaultValue={this.state.texte}/>

                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Popover
                                    isOpen={this.state.popoverOpenClose}
                                    content={<div class="dropdown-menu dropdown-menu show" style={{
                                        left: '0px',
                                        right: '0px',
                                        top: '-100px',
                                        zIndex: "99999",
                                        width: "620px"
                                    }}>
                                        <p class="dropdown-item dropdown-header"><span>1 SMS => 0 à 160 caractères inclus.</span><br/>
                                            <span>2 SMS => 160 à 306 caractères inclus (7 caractères sur chaque SMS servent à la concaténation)</span><br/>
                                            <span>3 SMS => 307 à 459 caractères inclus (7 caractères sur chaque SMS servent à la concaténation)</span>
                                        </p></div>}
                                >
                                    <Button variant="primary" size="sm"
                                            onClick={this.setIsPopoverOpen.bind(this, !this.state.popoverOpenClose)}>
                                        Information
                                    </Button>

                                </Popover>
                                <Form.Label style={{marginLeft: '30px'}}>Nombre de SMS estimé
                                    : {this.nombre_sms_estimer()}</Form.Label>
                                <Form.Label style={{marginLeft: '30px'}}>Nombre de caractères
                                    : {this.state.texte.length}</Form.Label>
                            </Form.Group>
                        </Card.Body>
                    </Card></Col>
            );
        }
    }

    render() {

        return (

            <Fragment>{this.affichage()}</Fragment>


        );


    }
}

export default Detail_message