import React from "react";
import axios from "axios";
import {withRouter} from "react-router-dom";
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import MD5 from "crypto-js";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import {
    Nav,
    Container,
    Row,
    Col,
    Tab,
    Table,
    Card,
    Button,
    Form,
    FloatingLabel,
    FormControl,
    InputGroup,
    Modal
} from 'react-bootstrap';
import Cookies from 'universal-cookie';
import {useState} from "react";
import {Link} from "react-router-dom";
import Accueil from "../Accueil";
import Swal from "sweetalert2";


class Login1 extends React.Component {
    constructor(props) {
        super(props);
        this.state =
            {
                id_cabinet: '',
                password: '',
                show: 'password',
                icon: 'far fa-eye',
                class_id_cabinet: '',
                class_password: ''

            }
        this.showPassword = this.showPassword.bind(this);
        this.Auth = this.Auth.bind(this);
    }

    Verif() {
        if (this.state.id_cabinet == "" && this.state.password == "") {
            this.setState({class_id_cabinet: "form-control is-invalid", class_password: "form_control is-invalid"});
            return false;
        }
        if (this.state.id_cabinet == "") {
            this.setState({class_id_cabinet: "form-control is-invalid"});
            return false;
        }
        if (this.state.password == "") {
            this.setState({class_password: "form-control is-invalid"});
            return false;
        }
        this.setState({class_id_cabinet: "form-control", class_password: "form_control"});
        return true;
    }

    Auth() {
        let res = this.Verif();
        if (res === true) {
            let optionurl = '/cabinet/connect';
            if (window.location.href.indexOf('localhost') > -1)

                var baseURL = 'http://localhost:8844' + optionurl;

            else if (window.location.href.indexOf('-dev') > -1)

                var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

            else

                var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;


            //const baseURL = "http://localhost:8844/cabinet/connect";
            const cookies = new Cookies();
            const params = {

                numero_serie: parseInt(this.state.id_cabinet),

            };
            var CryptoJS = require("crypto-js");
            var admincab = CryptoJS.MD5(this.state.id_cabinet + ":" + this.state.password).toString();

            var time = new Date().toUTCString();
            var data = JSON.stringify(params);
            data = CryptoJS.MD5(data).toString();
            var str = 'POST' + ',application/json,' + data + ',' + '/cabinet/connect' + ',' + time;
            var hmac = CryptoJS.HmacSHA256(str, admincab);
            str = btoa(hmac.toString());
            str = this.state.id_cabinet + ':' + str;
            axios.post(baseURL, {
                    numero_serie: parseInt(this.state.id_cabinet)
                },
                {
                    headers: {
                        'X-AUTH-TOKEN': str,
                        'Date-request': time,
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {


                    if (response.status == 200) {


                        if (cookies.get('numero_serie') === undefined) {
                            cookies.set('cabinet', this.state.id_cabinet, {path: '/'});
                            cookies.set('id_cabinet', response.data.id, {path: '/'});
                            cookies.set('token', admincab, {path: '/'});
                            localStorage.setItem('token', admincab);
                            localStorage.setItem('cabinet', this.state.id_cabinet);
                            localStorage.setItem('id_cabinet', response.data.id);


                        }
                        this.props.history.push("/accueil");


                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erreur',
                        text: 'identifiant ou mot de passe incorrect',

                    })
                });
        }


    }

    showPassword() {
        console.log(this.state.icon, this.state.show);
        if (this.state.show === "password") {
            this.setState({show: 'text', icon: 'far fa-eye-slash'});
        } else {
            this.setState({show: 'password', icon: 'far fa-eye'});
        }
    }

    componentDidMount() {

        if (localStorage.getItem('token') === null) {
            this.props.history.push("/login");
        } else {
            this.props.history.push("/accueil");
            this.forceUpdate();
        }
    }

    render() {

        const handleSubmit = (event) => {
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }


            //   setValidated(true);
        };

        const handleKeypress = e => {

            //it triggers by pressing the enter key
            if (e.key === "Enter") {

                this.Auth();
            }
        };
        return (
            <div className="row g-0 auth-wrapper">
                <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
                    <div className="auth-background-holder"></div>
                    <div className="auth-background-mask"></div>
                </div>

                <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
                    <div className="d-flex flex-column align-content-end">
                        <div className="auth-body mx-auto">
                            <p>Connexion</p>
                            <div className="auth-form-container text-start">
                                <Form onSubmit={handleSubmit}>
                                    <div className="email mb-3">
                                        <Form.Control
                                            className={this.state.class_id_cabinet}
                                            id="id_cabinet"
                                            name="id_cabinet"
                                            value={this.state.id_cabinet}
                                            placeholder="Code du cabinet"
                                            onChange={(e) => this.setState({id_cabinet: e.target.value})}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Ce champs est requis
                                        </Form.Control.Feedback>
                                    </div>

                                    <div className="password mb-3">
                                        <div className="input-group">
                                            <Form.Control type={this.state.show}
                                                          className={this.state.class_password}
                                                          name="password"
                                                          id="password"
                                                          value={this.state.password}
                                                          placeholder="Code Pin"
                                                          onChange={(e) => this.setState({password: e.target.value})}
                                                          onKeyPress={handleKeypress}
                                                          required
                                            />

                                            <button type="button" className="btn btn-outline-primary btn-sm"
                                                    onKeyPress={handleKeypress} onClick={(e) => this.showPassword()}><i
                                                className={this.state.icon}></i></button>

                                            <Form.Control.Feedback type="invalid">
                                                Ce champs est requis
                                            </Form.Control.Feedback>
                                        </div>


                                    </div>
                                    <div className="text-center">

                                        <Button className="btn btn-primary w-100 theme-btn mx-auto"
                                                onClick={this.Auth}>Valider</Button>
                                    </div>

                                </Form>
                                <hr/>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );

    }

}

export default withRouter(Login1);