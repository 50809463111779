import React, { Fragment } from 'react';
import { useTable, useSortBy, useFilters, useExpanded, usePagination } from 'react-table';
import { Table,Row,Col,Button,Container,Pagination } from 'react-bootstrap';
 

const Table_test = ({ columns, data, renderRowSubComponent,handler,pageLenght }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //rows,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: pageLenght }
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };
  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };
  const setActive = (value) => {
     if(value === pageIndex+1)
     {
       return ('active');
     }
  };
  const test = (event,a) => {
    console.log(event,a);
    console.log(event.target.id);
    handler(event.target.id,event);
  };
 
  return (
    <Fragment>
    <Table bordered hover {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th  {...column.getHeaderProps()}>
                <div   {...column.getSortByToggleProps()}>
                  {column.render('Header')}
                  {generateSortingIndicator(column)}
                </div>
                 
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row);
          return (
            <Fragment  key={row.getRowProps().key}>
              <tr  onClick={test}>
                {row.cells.map((cell) => {
                  return (
                    <td id={row.index} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
              {row.isExpanded && (
                <tr>
                  <td colSpan={visibleColumns.length}>
                    {renderRowSubComponent(row)}
                  </td>
                </tr>
              )}
            </Fragment>
          );
        })}
      </tbody>
    </Table>
    {pageOptions.length > 1 &&
    <Container>
    <Row>
     <Col md={4}> Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong></Col><Col md={{ span: 4, offset: 4 }}>

    <Pagination>
  <Pagination.First   onClick={() => gotoPage(0)} disabled={!canPreviousPage}/>
  <Pagination.Prev  onClick={previousPage} disabled={!canPreviousPage}/>
  <Pagination.Item onClick={() => gotoPage(0)} active={setActive(1)}>{1}</Pagination.Item>
   
  {pageOptions.slice(1).map(x=>  <Pagination.Item onClick={() => gotoPage(x)} active={setActive(x+1)}>{x+1}</Pagination.Item>)}
  
   
  <Pagination.Next onClick={nextPage} disabled={!canNextPage}/>
  <Pagination.Last  onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}/>
</Pagination>
  </Col>
  </Row>
</Container>
    }
    
 
    </Fragment>
    
  );
};

export default Table_test;