import React from 'react';
import styles from '../../styles/css/adminlte.min.css'
import { FiMail } from 'react-icons/fi';
import { Nav,Container,Row,Col,Tab,Table,Card,Button,InputGroup,FormControl,Form,Accordion,Spinner,Stack,Badge,Overlay } from 'react-bootstrap';
import { Popover } from 'react-tiny-popover';
import moment from 'moment';
import 'moment/locale/fr';
 
 

class Nav_bar extends React.Component{
  constructor(props)
  {
    super(props);
   
   this.state = {
     
     show: false,
     mois: '',
     mois_sms: '',
     mois_1: '',
     mois_1_sms: '',
     total: '',
     anne: '',
     anne_1: ''
   }
     
   
   
  }
  componentDidMount()
  {
    
   
    
  
    
  }
  componentWillReceiveProps()
  {
    
      if(this.props.conso)
      {
        moment.locale('fr');
        let conso = this.props.conso;
       
        if(conso.length > 1)
        {
         
          let total_sms = parseInt(conso[0].nb)+parseInt(conso[1].nb);
          let mois = moment(conso[0].mois).format("MMMM");
          mois = mois[0].toUpperCase() + mois.slice(1);
          let mois_1 = moment(conso[1].mois).format("MMMM"); 
          mois_1 = mois_1[0].toUpperCase() + mois_1.slice(1);
          let anne_1 = moment().subtract(1, 'months').format('YYYY');
          let anne = moment().format('YYYY');
          this.setState({mois: mois, mois_sms: conso[0].nb,mois_1: mois_1, mois_1_sms: conso[1].nb,total: total_sms,anne: anne,anne_1: anne_1});
        }
        else if(conso[0])
        {
         
          if(moment().format("M") != conso[0].mois)
          {
            // pas de conso  
            var mois = moment().format("MMMM");
          mois = mois[0].toUpperCase() + mois.slice(1);
          var mois_1 = moment(conso[0].mois).format("MMMM");
          mois_1 = mois_1[0].toUpperCase() + mois_1.slice(1);
          var conso_sms = 0;
          var conso_sms_1 = conso[0].nb;
        
          }
          else
          {
            var mois = moment(conso[0].mois).format("MMMM");
            mois = mois[0].toUpperCase() + mois.slice(1);
            var mois_1 = moment(conso[0].mois).subtract(1, 'months').format("MMMM");
            mois_1 = mois_1[0].toUpperCase() + mois_1.slice(1);
            var conso_sms = conso[0].nb;
            var conso_sms_1 = 0;
          }
          let anne_1 = moment().subtract(1, 'months').format('YYYY');
          let anne = moment().format('YYYY');
          let total_sms = parseInt(conso[0].nb);
          


          this.setState({mois: mois, mois_sms: conso_sms,mois_1: mois_1, mois_1_sms: conso_sms_1,total: total_sms,anne: anne,anne_1: anne_1});
  
        }
        else
        {
          let anne_1 = moment().subtract(1, 'months').format('YYYY');
          let anne = moment().format('YYYY');
          let mois = moment().format("MMMM");
          let mois_1 = moment().subtract(1, 'months').format("MMMM");
          this.setState({mois: mois, mois_sms: 0,mois_1: mois_1, mois_1_sms: 0,total: 0,anne: anne,anne_1: anne_1});
        }
     
      }
   
    
  }
 render()
 {
   
   const setPopover = () => { if(!this.state.show){this.setState({show: true}); }else {this.setState({show: false})}}
 
  return(
    <nav class="main-header navbar navbar-expand navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
    <li class="nav-item">
    <Popover
  isOpen={this.state.show}
  positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
  content={<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right show" style={{left: '',right: '0px',top: '53px',zIndex:"99999"}}>
  <span class="dropdown-item dropdown-header">Consommation SMS</span>
  <div class="dropdown-divider"></div>
  <a href="#" class="dropdown-item">
    <i class="fas fa-envelope mr-2"></i> {this.state.mois+" "+this.state.anne} : {this.state.mois_sms} SMS
    
  </a>
  <div class="dropdown-divider"></div>
  <a href="#" class="dropdown-item">
    <i class="fas fa-envelope mr-2"></i> {this.state.mois_1+" "+this.state.anne_1} : {this.state.mois_1_sms} SMS
    
  </a>
  
   
   
</div>}
>
    <Button  title="Consommation SMS" onClick={setPopover} variant="">
    <FiMail/>
   <Badge text="dark" style={{    fontSize: '.6rem',fontWeight: '300',padding: '2px 4px',right: '5px',top: '-9px'}}  bg="warning">{this.state.mois_sms}</Badge>
  <span className="visually-hidden">unread messages</span>
</Button>
 </Popover>
      </li>
    <li class="nav-item">
        <a class="nav-link" href="/#deconnexion" title="Déconnexion" role="button"><i class="fas fa-sign-out-alt"></i></a>
      </li>
    </ul>
     
  </nav>
  );
}
}
export default Nav_bar
 