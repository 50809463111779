import axios from "axios";
import React from "react";
class Token {

     
  static calcul_token(method,data,token,cab,resource)
  {
    var CryptoJS = require("crypto-js");
    if (token == null) token = 'vide';
    if (cab == null) cab = '00000';
    var time = new Date().toUTCString();
    if (data != null) {
        data = JSON.stringify(data);
        data = CryptoJS.MD5(data).toString()
    } else {
        data = '';
    }
   
    var str = method + ',application/json,' + data + ',' + resource + ',' + time;
    var hmac = CryptoJS.HmacSHA256(str, token);
    str = btoa(hmac.toString());
    str = cab + ':' + str;
    return str; 

  }
}

export default Token