import React from 'react';
import {useState} from "react";
import ReactDOM from 'react-dom';
import Menu from './views/Menu';
import Nav_bar from './views/Nav_bar';
import axios from "axios";
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import Token from '../utilities/Token'
import {
    Nav,
    Container,
    Row,
    Col,
    Tab,
    Table,
    Card,
    Button,
    InputGroup,
    FormControl,
    Form,
    Accordion,
    Spinner,
    Stack
} from 'react-bootstrap';
import {
    AiOutlineHistory,
    AiOutlineCalendar,
    AiOutlineSearch,
    AiOutlineCarryOut,
    AiOutlineFileSearch
} from "react-icons/ai";
import {VscCalendar} from "react-icons/vsc";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/style_datepicker.css";
import moment from 'moment'
import Tableau from './views/Tableau';
import Details from './views/Details';
import Details2 from './views/Details2';
import Table_test from './views/Table_test';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
import fr from "date-fns/locale/fr";

registerLocale("fr", fr)


class Accueil extends React.Component {
    constructor(props) {

        super(props);
        this.tab_active = React.createRef();
        if (window.location.href.indexOf('/#activite/prog') > -1) {
            var choix = 2;
        } else if (window.location.href.indexOf('/#activite/trace') > -1) {
            choix = 3;
        } else {
            choix = 1;
        }
        this.state = {
            val: '',
            date: new Date(),
            date_a: moment().subtract(7, 'days').toDate(),
            tab: [],
            tab1: [],
            tab2: [],
            tab_prog: [],
            tab_trace: [],
            choix: '',
            id_collonne: '',
            clicked_row: '',
            tab_1: ["Date", "Ligne créées", "Ligne présentes"],
            tab_2: ["Date", "Nouvel état", "Nouvelle date", "Portable", "Emplacement"],
            tab_3: ["Statut", "Date envoie", "Retour", "Portable"],
            tab_4: ["Code patient", "Nom patient", "Prénom patient", "Portable"],
            colonne_c: '1',
            choix_menu: choix,
            input_value: '',
            input2_value: '',
            input_class: 'form-control',
            input2_class: 'form-control',
            search: false,
            trace_fichier: [],
            trace_maj: [],
            trace_historique: [],
            loading: '',
            windowHeight: undefined,
            windowWidth: undefined,
            conso: ''

        }
        this.handler = this.handler.bind(this)
        this.handler1 = this.handler1.bind(this)
        this.get_historique = this.get_historique.bind(this)
        this.recherche_trace = this.recherche_trace.bind(this)
        this.updateInputValue = this.updateInputValue.bind(this)
        this.updateInputValue1 = this.updateInputValue1.bind(this)
    }

    handleResize = () => this.setState({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
    });

    Affichage() {

        const cookies = new Cookies();
        var time = new Date().toUTCString();
        /*var id_cabinet = cookies.get('id_cabinet');
        var cabinet = cookies.get('cabinet');
        var token = cookies.get('token');*/
        var id_cabinet = localStorage.getItem('id_cabinet');
        var cabinet = localStorage.getItem('cabinet');
        var token = localStorage.getItem('token');
        var tokens = Token.calcul_token("GET", null, token, cabinet, '/cabinet/' + id_cabinet);
        let optionurl = '/cabinet/' + id_cabinet;
        if (window.location.href.indexOf('localhost') > -1)

            var baseURL = 'http://localhost:8844' + optionurl;

        else if (window.location.href.indexOf('-dev') > -1)

            var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

        else

            var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;
        //const baseURL = "http://localhost:8844/cabinet/"+id_cabinet;


        const config = {
            headers: {
                'X-AUTH-TOKEN': tokens,
                'Date-request': time,
                'Content-type': 'application/json'
            },
            data: {}
        }

        axios.get(baseURL, config)
            .then((response) => {


                this.setState({val: response.data.nom});
                cookies.set('praticien', response.data.nom, {path: '/'});
                localStorage.setItem('praticien', response.data.nom);
                // Local

                this.render();
                if (response.status == 200) {


                }
            })
            .catch(error => {

            });


    }

    get_conso() {
        var time = new Date().toUTCString();
        var id_cabinet = localStorage.getItem('id_cabinet');
        var cabinet = localStorage.getItem('cabinet');
        var token = localStorage.getItem('token');
        var tokens = Token.calcul_token("GET", null, token, cabinet, '/cabinet/conso');
        let optionurl = '/cabinet/conso';
        if (window.location.href.indexOf('localhost') > -1)

            var baseURL = 'http://localhost:8844' + optionurl;

        else if (window.location.href.indexOf('-dev') > -1)

            var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

        else

            var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;

        const config = {
            headers: {
                'X-AUTH-TOKEN': tokens,
                'Date-request': time,
                'Content-type': 'application/json'
            },
            data: {}
        }


        axios.get(baseURL, config)
            .then((response) => {

                if (response.status === 200) {
                    localStorage.setItem('conso', JSON.stringify(response.data.data));
                    // a voire
                    this.setState({conso: response.data.data})
                    this.forceUpdate();


                } else {

                }
            })
            .catch(error => {

            });


    }

    get_programmation() {
        const cookies = new Cookies();
        var time = new Date().toUTCString();
        /*var cabinet = cookies.get('cabinet');
        var token = cookies.get('token');*/
        var id_cabinet = localStorage.getItem('id_cabinet');
        var cabinet = localStorage.getItem('cabinet');
        var token = localStorage.getItem('token');
        var tokens = Token.calcul_token("GET", null, token, cabinet, '/activite/prog');
        let optionurl = '/activite/prog?age=1&start=0&limit=25';
        if (window.location.href.indexOf('localhost') > -1)

            var baseURL = 'http://localhost:8844' + optionurl;

        else if (window.location.href.indexOf('-dev') > -1)

            var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

        else

            var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;


        const config = {
            headers: {
                'X-AUTH-TOKEN': tokens,
                'Date-request': time,
                'Content-type': 'application/json'
            },
            data: {}
        }


        axios.get(baseURL, config)
            .then((response) => {

                if (response.status === 200) {

                    let array = [];
                    let array1 = [];
                    let array2 = [];

                    Object.keys(response.data).forEach(key => {


                        if ((moment(response.data[key].dateHeureEnvoiSms1).format('DD/MM/YYYY HH:mm:ss')) == "Invalid date" || response.data[key].dateHeureEnvoiSms1 === null) {
                            response.data[key].dateHeureEnvoiSms1 = "";
                        } else {
                            response.data[key].dateHeureEnvoiSms1 = moment(response.data[key].dateHeureEnvoiSms1).format('DD/MM/YYYY HH:mm:ss');
                        }
                        if ((moment(response.data[key].dateHeureEnvoiSms2).format('DD/MM/YYYY HH:mm:ss')) == "Invalid date" || response.data[key].dateHeureEnvoiSms2 === null) {
                            response.data[key].dateHeureEnvoiSms2 = "";
                        } else {
                            response.data[key].dateHeureEnvoiSms2 = moment(response.data[key].dateHeureEnvoiSms2).format('DD/MM/YYYY HH:mm:ss');
                        }
                        if ((moment(response.data[key].rdv).format('DD/MM/YYYY HH:mm:ss')) == "Invalid date" || response.data[key].rdv === null) {
                            response.data[key].rdv = "";
                        } else {
                            response.data[key].rdv = moment(response.data[key].rdv).format('DD/MM/YYYY HH:mm:ss');
                        }

                        let ligne = [response.data[key]];

                        array.push(ligne);
                        ligne = null;
                    });
                    this.setState({tab_prog: array});


                } else {

                }
            })
            .catch(error => {

            });


    }

    get_historique(date_debut, date_fin) {
        const cookies = new Cookies();
        var time = new Date().toUTCString();
        if (date_debut && date_fin) {
            var datedeb = date_debut;
            var datefin = date_fin;
        } else {

            var datedeb = moment().subtract(7, 'days').format('YYYY-MM-DD');// a changer .format('DD/MM/YYYY
            var datefin = moment().format('YYYY-MM-DD');
        }

        /*  var cabinet = cookies.get('cabinet');
          var token = cookies.get('token');*/
        var id_cabinet = localStorage.getItem('id_cabinet');
        var cabinet = localStorage.getItem('cabinet');
        var token = localStorage.getItem('token');
        var tokens = Token.calcul_token("GET", null, token, cabinet, '/activite/histo');

        let optionurl = "/activite/histo?datedeb=" + datedeb + "&datefin=" + datefin;
        if (window.location.href.indexOf('localhost') > -1)

            var baseURL = 'http://localhost:8844' + optionurl;

        else if (window.location.href.indexOf('-dev') > -1)

            var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

        else

            var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;

        //const baseURL = "http://localhost:8844/activite/histo?datedeb="+datedeb+"&datefin="+datefin;


        const config = {
            headers: {
                'X-AUTH-TOKEN': tokens,
                'Date-request': time,
                'Content-type': 'application/json'
            },
            data: {}
        }
        this.setState({loading: true}, () => {
            axios.get(baseURL, config)
                .then((response) => {
                    this.setState({
                        loading: false
                    });
                    if (response.status == 200) {
                        let array = [];
                        let array1 = [];
                        let array2 = [];
                        Object.keys(response.data.fichiers).forEach(key => {

                            let ligne = [moment(response.data.fichiers[key].dateHeure).format('DD/MM/YYYY HH:mm:ss'), response.data.fichiers[key].nbFileLines, response.data.fichiers[key].nbCreatedLines, response.data.fichiers[key].id]
                            array.push(ligne);
                            ligne = null;
                        });
                        Object.keys(response.data.majrdv).forEach(key => {

                            let date;
                            let date1;
                            if ((moment(response.data.majrdv[key].dateHeureMaj).format('DD/MM/YYYY HH:mm:ss')) == "Invalid date") {
                                date = "";
                            } else {
                                date = moment(response.data.majrdv[key].dateHeureMaj).format('DD/MM/YYYY HH:mm:ss');
                            }
                            if ((moment(response.data.majrdv[key].rdvNewDate).format('DD/MM/YYYY HH:mm:ss')) == "Invalid date") {
                                date1 = "";
                            } else {
                                date1 = moment(response.data.majrdv[key].rdvNewDate).format('DD/MM/YYYY HH:mm:ss');
                            }

                            let ligne1 = [date, response.data.majrdv[key].rdvNewState, date1, response.data.majrdv[key].portable, response.data.majrdv[key].emplacement, response.data.majrdv[key].rdvId, response.data.majrdv[key].rdvNewState, response.data.majrdv[key].action];
                            array1.push(ligne1);
                            ligne1 = null;
                        });
                        Object.keys(response.data.envois).forEach(key => {

                            let date;

                            if ((moment(response.data.envois[key].dateHeureEnvoi).format('DD/MM/YYYY HH:mm:ss')) == "Invalid date") {
                                date = "";
                            } else {
                                date = moment(response.data.envois[key].dateHeureEnvoi).format('DD/MM/YYYY HH:mm:ss');
                            }
                            let ligne1 = [response.data.envois[key].statut, date, response.data.envois[key].messageErreur, response.data.envois[key].portable, response.data.envois[key].dateCreation, response.data.envois[key].rdvId, response.data.envois[key].smsId, response.data.envois[key].texte];
                            array2.push(ligne1);
                            ligne1 = null;
                        });


                        this.setState({tab: array});
                        this.setState({tab1: array1});
                        this.setState({tab2: array2});


                    }
                })
                .catch(error => {

                });
        });

    }

    componentDidMount() {
        const value = queryString.parse(this.props.location.search);
        const cookies = new Cookies();
        if (value.cle) {

            this.get_cle_info(value.cle);


        } else if (localStorage.getItem('token') === null) {
            this.props.history.push("/login");
        } else {
            this.Affichage();
            this.get_historique();
            this.get_programmation();
            this.get_conso();
        }

        if (this.props.match.params.portable) {
            this.setState({input_value: this.props.match.params.portable})
        }
        if (this.props.match.params.rdv) {
            this.setState({input2_value: this.props.match.params.rdv})
        }

        this.handleResize();
        window.addEventListener('resize', this.handleResize)


    }

    get_cle_info(cle) {
        const cookies = new Cookies();
        let optionurl = "/cabinet/key/connect/" + cle;
        if (window.location.href.indexOf('localhost') > -1)

            var baseURL = 'http://localhost:8844' + optionurl;

        else if (window.location.href.indexOf('-dev') > -1)

            var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

        else

            var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;


        axios.get(baseURL, {})
            .then((response) => {


                let array = [];
                let array_msg = [];
                if (response.status == 200) {
                    if (response.message == "Token incorrect") {
                        this.props.history.push("/login");
                    }

                    cookies.set('cabinet', response.data.serie, {path: '/'});
                    cookies.set('id_cabinet', response.data.id, {path: '/'});
                    cookies.set('token', response.data.token, {path: '/'});
                    // set item
                    localStorage.setItem('cabinet', response.data.serie);
                    localStorage.setItem('id_cabinet', response.data.id);
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('serie', response.data.serie);
                    this.Affichage();
                    this.get_historique();
                    this.get_programmation();
                    this.get_conso();

                    let url = window.location.href;
                    let index_cle = url.indexOf("cle=");
                    url = url.substr(0, index_cle);
                    window.location.href = url;
                }

            })
            .catch(error => {
                this.props.history.push("/login");
            });


    }

    rechercher() {
        this.setState({clicked_row: ''}, () => {

            let date_a = moment(this.state.date_a, "DD-MM-YYYY").format("YYYY-MM-DD");
            let date = moment(this.state.date, "DD-MM-YYYY").format("YYYY-MM-DD")

            this.get_historique(date_a, date);
        });

    }

    recherche_trace() {

        const cookies = new Cookies();
        var time = new Date().toUTCString();
        /*var cabinet = cookies.get('cabinet');
        var token = cookies.get('token');*/
        var id_cabinet = localStorage.getItem('id_cabinet');
        var cabinet = localStorage.getItem('cabinet');
        var token = localStorage.getItem('token');
        var tokens = Token.calcul_token("GET", null, token, cabinet, '/activite/histodetail');
        let date_a = moment(this.state.date_a, "DD-MM-YYYY").format("YYYY-MM-DD");
        let date = moment(this.state.date, "DD-MM-YYYY").format("YYYY-MM-DD")


        let optionurl = "/activite/histodetail?datedeb=" + date_a + "&datefin=" + date + "&portable=" + this.state.input_value + "&id_rdv=" + this.state.input2_value + "&page=1&start=0&limit=25";
        if (window.location.href.indexOf('localhost') > -1)

            var baseURL = 'http://localhost:8844' + optionurl;

        else if (window.location.href.indexOf('-dev') > -1)

            var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

        else

            var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;


        // const baseURL = "http://localhost:8844/activite/histodetail?datedeb="+date_a+"&datefin="+date+"&portable="+this.state.input_value+"&id_rdv="+this.state.input2_value+"&page=1&start=0&limit=25"


        const config = {
            headers: {
                'X-AUTH-TOKEN': tokens,
                'Date-request': time,
                'Content-type': 'application/json'
            },
            data: {}
        }

        if (this.state.input_value === "" && this.state.input2_value === "") {
            this.setState({input_class: "form-control is-invalid", input2_class: "form-control is-invalid"})
            this.test_alert();
        } else {
            this.setState({clicked_row: '', search: true});
            if (this.state.input_class === "form-control is-invalid") {
                this.setState({input_class: "form-control", input2_class: "form-control"})
            }
            this.setState({loading: true}, () => {
                axios.get(baseURL, config)
                    .then((response) => {
                        this.setState({loading: false});
                        if (response.status === 200) {

                            let array = [];
                            let array1 = [];
                            let array2 = [];


                            Object.keys(response.data).forEach(key => {
                                if (response.data[key].source === "fichier") {
                                    response.data[key].type_donnee = "Réception de fichier cabinet";
                                    let line = response.data[key].line.split("\t");
                                    response.data[key].line = line;
                                } else if (response.data[key].source === "maj") {
                                    response.data[key].type_donnee = "Modification rendez-vous";
                                    if (response.data[key].data.rdv_new_state === "Cancelled") {
                                        let line = response.data[key].data.action.split("\n");
                                        response.data[key].data.action = line;
                                        response.data[key].data.rdvNewDate = '';
                                    } else {
                                        let line = response.data[key].data.action.split("\n");
                                        response.data[key].data.action = line;
                                        response.data[key].data.rdvNewDate = moment(response.data[key].data.rdvNewDate).format("DD/MM/YYYY HH:mm:ss");
                                    }
                                } else {
                                    response.data[key].type_donnee = "Envoi de message";
                                    response.data[key].data.dateHeureEnvoi = moment(response.data[key].data.dateHeureEnvoi).format("DD/MM/YYYY HH:mm:ss");
                                }
                                array.push(response.data[key])

                            });

                            array.sort(function compare(a, b) {
                                var dateA = new Date(a.date);
                                var dateB = new Date(b.date);
                                return dateA - dateB;
                            });
                            this.setState({trace_fichier: array});


                        } else {

                        }
                    })
                    .catch(error => {

                    });
            });
        }

    }

    handler(val, val1) {
        this.setState({choix: val, clicked_row: val1, id_collonne: val});
        this.state.clicked_row = val1;

    }

    handler1() {

    }

    set_data_histo(props) {
        if (props === "1") {
            return this.state.tab;
        } else if (props === "2") {
            return this.state.tab1;
        } else {
            return this.state.tab2;
        }

    }

    colonne_tab() {

        if (this.state.choix_menu === 1) {
            if (this.state.colonne_c === '1') {
                if (this.state.tab.length === 0) {
                    return ('Pas de résultat disponible');
                }
                return (<Tableau ref={this.tab_active} handler={this.handler} tab={this.state.tab}
                                 col={[this.state.tab_1]}></Tableau>);
            } else if (this.state.colonne_c === '2') {
                if (this.state.tab1.length === 0) {
                    return ('Pas de résultat disponible');
                }
                console.log("clique sur 2");
                return (<Tableau ref={this.tab_active} col_num="5" handler={this.handler} tab={this.state.tab1}
                                 col={[this.state.tab_2]}></Tableau>);
            } else {
                if (this.state.tab2.length === 0) {
                    return ('Pas de résultat disponible');
                }
                console.log("clique sur 3");
                return (<Tableau ref={this.tab_active} col_num="4" handler={this.handler} tab={this.state.tab2}
                                 col={[this.state.tab_3]}></Tableau>);
            }
        } else {
            if (this.state.tab_prog.length === 0) {
                return ('Pas de résultat disponible');
            }
            return (<Tableau ref={this.tab_active} col_num="4" handler={this.handler} tab={this.state.tab_prog}
                             col={[this.state.tab_4]}></Tableau>);
        }


    }

    handleChange(value, e) {

        console.log(e.target.value);
        this.setState({date_a: value})
    }

    handleChange1(value, e) {
        console.log(value);
        console.log(e.target.value);
        this.setState({date: value});
    }

    updateInputValue(evt) {
        this.setState({
            input2_value: evt.target.value
        });
    }

    updateInputValue1(evt) {
        this.setState({
            input_value: evt.target.value
        });
    }

    get_class_input() {
        if (this.state.search === true && (this.state.input_value !== "" || this.state.input_value2 !== "")) {
            return "form-control";
        } else {
            return "form-control is-invalid";
        }

    }

    get_colonne(props) {
        const columns = [
            {
                Header: () => null,
                id: 'expander', // 'id' is required
                Cell: ({row}) => (
                    <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? '➖' : '➕'}
        </span>
                ),
            },
            {
                Header: 'Date',
                accessor: d => {
                    return moment(d.date)
                        .format("DD/MM/YYYY HH:mm:ss")
                }

            },
            {
                Header: 'Type de données',
                accessor: 'type_donnee',
                disableSortBy: true,

                filter: 'equals',
            },


        ];
        const columns1 = [
            {
                Header: () => null,
                id: 'expander', // 'id' is required
                Cell: ({row}) => (
                    <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? '➖' : '➕'}
        </span>
                ),
            },
            {
                Header: 'Date',
                accessor: 'date',
                sortType: "datetime"
            },
            {
                Header: 'Portable',
                accessor: 'data.portable',
                disableSortBy: true,

                filter: 'equals',
            },
            {
                Header: 'ID rendez-vous',
                accessor: 'data.id',
            },
            {
                Header: 'Statut rendez-vous',
                accessor: 'data.rdv_new_state',
            },
            {
                Header: 'Nouvelle date',
                accessor: 'data.rdv_new_date',
            },
            {
                Header: 'Emplacement',
                accessor: 'data.emplacement',
            },


        ];
        const columns2 = [
            {
                Header: () => null,
                id: 'expander', // 'id' is required
                Cell: ({row}) => (
                    <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? '➖' : '➕'}
        </span>
                ),
            },
            {
                Header: 'Date',
                accessor: 'date',
                disableSortBy: true,

                filter: 'equals',
            },
            {
                Header: 'Date envoi',
                accessor: 'data.date_heure_envoi',
                disableSortBy: true,

                filter: 'equals',
            },
            {
                Header: 'Portable',
                accessor: 'data.portable',
                disableSortBy: true,

                filter: 'equals',
            },
            {
                Header: 'ID rendez-vous',
                accessor: 'data.rdv_id',
            },
            {
                Header: 'Statut',
                accessor: 'data.statut',
            },


        ];
        const columns4 = [
            {
                Header: () => null,
                id: 'expander', // 'id' is required
                Cell: ({row}) => (
                    <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? '➖' : '➕'}
        </span>
                ),
            },
            {
                Header: 'Date',
                accessor: 'date',
                disableSortBy: true,

                filter: 'equals',
            },
            {
                Header: 'type de données',
                accessor: 'data.date_heure_envoi',
                disableSortBy: true,

                filter: 'equals',
            }
        ]
        if (props === 1) {
            return (<Table_test columns={columns} data={this.state.trace_fichier} handler={this.handler1}
                                renderRowSubComponent={this.renderRowSubComponent} pageLenght={10}/>);
        } else if (props === 2) {
            return (<Table_test columns={columns1} data={this.state.trace_maj} handler={this.handler1}
                                renderRowSubComponent={this.renderRowSubComponent1} pageLenght={10}/>);
        } else {
            return (<Table_test columns={columns2} data={this.state.trace_historique} handler={this.handler1}
                                renderRowSubComponent={this.renderRowSubComponent2} pageLenght={10}/>);
        }

    }

    renderRowSubComponent(row) {
        const {
            line,
            data,
            num_line,


        } = row.original;

        if (line) { // fichier
            return (
                <Card style={{margin: '0 auto'}}>
                    <Card.Body>
                        <Card.Title>

                        </Card.Title>
                        <Card.Text>

                            <Container>
                                <Row>
                                    <Col>
                                        <strong>Ligne</strong> : {num_line} <br/>
                                        <strong>Nom patient</strong> : {line[0]} <br/>
                                        <strong>Prenom patient</strong> : {line[1]} <br/>
                                        <strong>Date de naissance</strong> : {line[2]} <br/>
                                        <strong>Nom parent</strong> : {line[3]} <br/>
                                        <strong>Portable</strong> : {line[4]} <br/>
                                        <strong>Code type rendez-vous</strong> : {line[5]} <br/>
                                        <strong>Nom type rendez-vous</strong> : {line[6]} <br/>
                                    </Col>
                                    <Col>
                                        <strong>Rendez-vous</strong> : {line[7]} <br/>
                                        <strong>Code Praticien</strong> : {line[8]} <br/>
                                        <strong>Nom Praticien</strong> : {line[9]} <br/>
                                        <strong>Code message</strong> : {line[10]} <br/>
                                        <strong>Civilite patient</strong> : {line[12]} <br/>
                                        <strong>Id rendez-vous</strong> : {line[11]} <br/>
                                        <strong>Code Patient</strong> : {line[13]} <br/>
                                        <strong>Clé rendez-vous</strong> : {line[14]} <br/></Col>
                                </Row>
                            </Container>

                        </Card.Text>
                    </Card.Body>
                </Card>
            );
        } else if (data.message_erreur) // envoi message
        {
            return (
                <Card style={{margin: '0 auto'}}>
                    <Card.Body>
                        <Card.Title>
                            Envoi de message
                        </Card.Title>
                        <Card.Text>
                            <strong>Date envoi</strong> : {data.date_heure_envoi} <br/>
                            <strong>Portable</strong> : {data.portable} <br/>
                            <strong>ID RDV</strong> : {data.rdv_id} <br/>
                            <strong>ID SMS</strong> : {data.sms_id} <br/>
                            <strong>Portable</strong> : {data.portable} <br/>
                            <strong>Retour</strong> : {data.message_erreur} <br/>
                        </Card.Text>
                    </Card.Body>
                </Card>
            );
        } else {
            const listItems = data.action.map((d) => <li key={d.name}>{d}</li>);
            return (
                <Card style={{margin: '0 auto'}}>
                    <Card.Body>
                        <Card.Title>
                            Réception d'un mise à jour de rendez-vous
                        </Card.Title>
                        <Card.Text>
                            <Container>
                                <Row>
                                    <Col>
                                        <strong>Portable</strong> : {data.portable} <br/>
                                        <strong>ID RDV</strong> : {data.rdv_id} <br/>
                                        <strong>Statut</strong> : {data.rdv_new_state} <br/>
                                        <strong>Nouvelle date</strong> : {data.rdv_new_date} <br/>
                                        <strong>Emplacement</strong> : {data.emplacement} <br/>
                                    </Col>
                                    <Col>
                                        {listItems}
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Text>
                    </Card.Body>
                </Card>
            );
        }


    }

    renderRowSubComponent1(row) {
        const {
            data
        } = row.original;
        console.log(data);
        const listItems = data.action.map((d) => <li key={d.name}>{d}</li>);
        console.log(listItems);
        return (
            <Card style={{margin: '0 auto'}}>
                <Card.Body>
                    <Card.Title>

                    </Card.Title>
                    <Card.Text>
                        {listItems}
                    </Card.Text>
                </Card.Body>
            </Card>
        );


    }

    renderRowSubComponent2(row) {
        const {
            data
        } = row.original;


        return (
            <Card style={{margin: '0 auto'}}>
                <Card.Body>
                    <Card.Title>


                    </Card.Title>
                    <Card.Text>
                        <strong>Retour</strong> : {data.message_erreur} <br/>
                        <strong>Texte</strong> : {data.texte} <br/>

                    </Card.Text>
                </Card.Body>
            </Card>
        );


    }

    test_alert() {
        toast.error('Veuillez remplir un des champs suivants ', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });


    }

    affiche_trace() {
        if (this.state.search === true) {
            if (this.state.trace_fichier.length === 0 && this.state.trace_maj.length === 0 && this.state.trace_historique.length === 0) {
                return ("Aucun résulat")
            }
            return (
                this.get_colonne(1)
            );
        } else {
            return ("Effectuer une recherche");
        }
    }

    affiche_btn() {
        const openDatepicker = () => this._calendar.setOpen(true);
        const openDatepicker1 = () => this._calendar1.setOpen(true);
        if (this.state.windowWidth < 1610) {
            return (<div><Form.Group controlId="duedate" style={{display: "flex"}}>
                <InputGroup.Text style={{color: "#f8f9fa", backgroundColor: "#12203a"}}>Du</InputGroup.Text>
                <DatePicker className='form-control' selected={this.state.date_a}
                            ref={(c) => this._calendar = c} locale="fr" dateFormat="dd/MM/yyyy"
                            onChange={(value, e) => this.handleChange(value, e)}/>
                <VscCalendar onClick={() => {
                    openDatepicker()
                }} className="fa-lg ml-n4 mt-2" style={{zIndex: "9999"}}/>
            </Form.Group>
                <Form.Group controlId="duedate" style={{display: "flex"}}>
                    <InputGroup.Text style={{color: "#f8f9fa", backgroundColor: "#12203a"}}>Au</InputGroup.Text>
                    <DatePicker ref={(c) => this._calendar1 = c} className='form-control' selected={this.state.date}
                                locale="fr" dateFormat="dd/MM/yyyy"
                                onChange={(value, e) => this.handleChange1(value, e)}/>
                    <VscCalendar onClick={() => {
                        openDatepicker1()
                    }} className="fa-lg ml-n4 mt-2" style={{zIndex: "9999"}}/>
                </Form.Group>
                <Button variant="primary" className="w-100 rounded-left"
                        onClick={this.rechercher.bind(this)}><AiOutlineSearch/>Rechercher</Button></div>)

        } else {
            return (<Stack direction="horizontal" gap={3}>
                <Form.Group controlId="duedate" style={{display: "flex"}}>
                    <InputGroup.Text style={{color: "#f8f9fa", backgroundColor: "#12203a"}}>Du</InputGroup.Text>
                    <DatePicker className='form-control' ref={(c) => this._calendar = c} selected={this.state.date_a}
                                locale="fr" dateFormat="dd/MM/yyyy"
                                onChange={(value, e) => this.handleChange(value, e)}/> <VscCalendar onClick={() => {
                    openDatepicker()
                }} className="fa-lg ml-n4 mt-2" style={{zIndex: "9999"}}/>

                </Form.Group>
                <Form.Group controlId="duedate" style={{display: "flex"}}><InputGroup.Text
                    style={{color: "#f8f9fa", backgroundColor: "#12203a"}}>Au</InputGroup.Text><DatePicker
                    ref={(c) => this._calendar1 = c} className='form-control' selected={this.state.date} locale="fr"
                    dateFormat="dd/MM/yyyy" onChange={(value, e) => this.handleChange1(value, e)}/> <VscCalendar
                    onClick={() => {
                        openDatepicker1()
                    }} className="fa-lg ml-n4 mt-2" style={{zIndex: "9999"}}/></Form.Group>


                <Button variant="primary" className="rounded-left"
                        onClick={this.rechercher.bind(this)}><AiOutlineSearch/>Rechercher</Button>


            </Stack>);


        }
    }

    affiche_btn1() {
        const openDatepicker = () => this._calendar.setOpen(true);
        const openDatepicker1 = () => this._calendar1.setOpen(true);
        if (this.state.windowWidth < 720) {
            return (<Stack className="col-md-5 mx-auto" gap={2}>
                <Form.Group controlId="duedate" style={{display: "flex"}}>
                    <InputGroup.Text style={{color: "#f8f9fa", backgroundColor: "#12203a"}}>Du</InputGroup.Text>
                    <DatePicker className='form-control' ref={(c) => this._calendar = c} selected={this.state.date_a}
                                locale="fr" dateFormat="dd/MM/yyyy"
                                onChange={(value, e) => this.handleChange(value, e)}/> <VscCalendar onClick={() => {
                    openDatepicker()
                }} className="fa-lg ml-n4 mt-2" style={{zIndex: "9999"}}/>
                </Form.Group>
                <Form.Group controlId="duedate" style={{display: "flex"}}>
                    <InputGroup.Text style={{color: "#f8f9fa", backgroundColor: "#12203a"}}>Au</InputGroup.Text>
                    <DatePicker className='form-control' ref={(c) => this._calendar1 = c} selected={this.state.date}
                                locale="fr" dateFormat="dd/MM/yyyy"
                                onChange={(value, e) => this.handleChange1(value, e)}/> <VscCalendar onClick={() => {
                    openDatepicker1()
                }} className="fa-lg ml-n4 mt-2" style={{zIndex: "9999"}}/>
                </Form.Group>
                <Form.Group controlId="duedate" style={{display: "flex"}}>
                    <Form.Control type="text" onChange={this.updateInputValue1.bind(this)}
                                  value={this.state.input_value} className={this.state.input_class}
                                  placeholder="Téléphone"/>
                </Form.Group>
                <Button variant="primary" className="rounded-left"
                        onClick={this.recherche_trace.bind(this)}><AiOutlineSearch/>Rechercher</Button>
            </Stack>);
        } else {
            return (<Stack direction="horizontal" gap={3}>
                <Form.Group controlId="duedate" style={{display: "flex"}}>
                    <InputGroup.Text style={{color: "#f8f9fa", backgroundColor: "#12203a"}}>Du</InputGroup.Text>
                    <DatePicker className='form-control' ref={(c) => this._calendar = c} selected={this.state.date_a}
                                locale="fr" dateFormat="dd/MM/yyyy"
                                onChange={(value, e) => this.handleChange(value, e)}/> <VscCalendar onClick={() => {
                    openDatepicker()
                }} className="fa-lg ml-n4 mt-2" style={{zIndex: "9999"}}/>
                </Form.Group>
                <Form.Group controlId="duedate" style={{display: "flex"}}>
                    <InputGroup.Text style={{color: "#f8f9fa", backgroundColor: "#12203a"}}>Au</InputGroup.Text>
                    <DatePicker className='form-control' ref={(c) => this._calendar1 = c} selected={this.state.date}
                                locale="fr" dateFormat="dd/MM/yyyy"
                                onChange={(value, e) => this.handleChange1(value, e)}/> <VscCalendar onClick={() => {
                    openDatepicker1()
                }} className="fa-lg ml-n4 mt-2" style={{zIndex: "9999"}}/>
                </Form.Group>
                <Form.Group controlId="duedate" style={{display: "flex"}}>
                    <Form.Control type="text" onChange={this.updateInputValue.bind(this)}
                                  value={this.state.input2_value} className={this.state.input2_class}
                                  placeholder="ID rendez-vous"/>
                </Form.Group>
                <Form.Group controlId="duedate" style={{display: "flex"}}>
                    <Form.Control type="text" onChange={this.updateInputValue1.bind(this)}
                                  value={this.state.input_value} className={this.state.input_class}
                                  placeholder="Téléphone"/>
                </Form.Group>
                <Button variant="primary" className="rounded-left"
                        onClick={this.recherche_trace.bind(this)}><AiOutlineSearch/>Rechercher</Button>
            </Stack>);
        }
    }

    tab_affichage() {

        const handleSelect = (eventKey) => {
            this.setState({colonne_c: eventKey, clicked_row: ''});
            if (this.state.clicked_row !== '') {
                this.tab_active.current.unset_active();
            }
        };
        if (this.state.choix_menu === 1) {
            return (<Row>
                <Col>
                    <Card><Card.Header> <Container fluid>
                        {this.affiche_btn()}

                    </Container></Card.Header></Card>
                    <Card>
                        <Card.Header>
                            <Nav variant="tabs" activeKey={this.state.colonne_c} onSelect={handleSelect}>
                                <Nav.Item>
                                    <Nav.Link eventKey="1">Fichiers Orthokis</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="2">Mises à jour</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="3">Journal</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Card.Header>
                        <Card.Body style={{display: 'block', height: '600px', overflowY: 'scroll',}}>
                            <div class="w-100 d-flex justify-content-center align-items-center">

                            </div>
                            {this.state.loading ? <div><br/><br/><Spinner animation="border" role="status" style={{
                                marginTop: "20%",
                                marginLeft: "50%",
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> <br/> <br/> <br/> <br/> <br/> <br/></div> : this.colonne_tab()}

                        </Card.Body>
                    </Card></Col>
                <Col>
                    <Details type={this.state.colonne_c} val={this.state.choix} id={this.state.id_collonne}
                             data={this.set_data_histo(this.state.colonne_c)} index={this.state.clicked_row}/>
                </Col>
            </Row>);
        } else if (this.state.choix_menu === 2) {
            return (<Row>
                <Col>

                    <Card>
                        <Card.Header>
                            Information patient
                        </Card.Header>
                        <Card.Body style={{display: 'block', height: '600px', overflowY: 'scroll',}}>
                            {this.colonne_tab()}
                        </Card.Body>
                    </Card></Col>
                <Col>
                    <Details2 type={this.state.colonne_c} val={this.state.choix} id={this.state.id_collonne}
                              data={this.state.tab_prog} index={this.state.clicked_row}/>
                </Col>
            </Row>);
        } else {
            return (<Row className="h-100">


                <Card className="h-100">
                    <Card.Header>
                        {/*
    <Row>
    <Col md="auto">
    <Form.Group controlId="duedate" style={{ display: "flex" }}>
    <InputGroup.Text style={{ color: "#f8f9fa",backgroundColor: "#12203a" }}>Du</InputGroup.Text>
    <DatePicker className='form-control' selected={this.state.date_a}   locale="fr"  dateFormat="dd/MM/yyyy" onChange={(value, e) => this.handleChange(value, e)}  /> <VscCalendar className="fa-lg ml-n4 mt-2" style={{zIndex:"9999"}}/>
    </Form.Group>
    </Col>
    <Col md="auto">
    <Form.Group controlId="duedate" style={{ display: "flex" }}>
    <InputGroup.Text style={{ color: "#f8f9fa",backgroundColor: "#12203a" }}>Au</InputGroup.Text>
    <DatePicker className='form-control' selected={this.state.date}   locale="fr"  dateFormat="dd/MM/yyyy"  onChange={(value, e) => this.handleChange1(value, e)} /> <VscCalendar className="fa-lg ml-n4 mt-2" style={{zIndex:"9999"}}/>
    </Form.Group>
    </Col>
    <Col md="auto">
    <Form.Group controlId="duedate" style={{ display: "flex" }}>
    <Form.Control type="text"  onChange={this.updateInputValue1.bind(this)} value={this.state.input_value} className={this.state.input_class} placeholder="Téléphone" />
    </Form.Group>
    </Col>
    <Col md="auto">
    <Form.Group controlId="duedate" style={{ display: "flex" }}>
    <Form.Control type="text"  onChange={this.updateInputValue.bind(this)} value={this.state.input2_value} className={this.state.input2_class} placeholder="ID rendez-vous" />
    </Form.Group>
    </Col>
    <Col>
    <Button  variant="primary" className=" ml-4 w-75 rounded-left" onClick={ this.recherche_trace.bind(this)} ><AiOutlineSearch/>Rechercher</Button>
    </Col>
    <ToastContainer />
     
     </Row>
     */}
                        <ToastContainer/>
                        {this.affiche_btn1()}
                    </Card.Header>
                    <Card.Body>
                        {this.state.loading ?
                            <React.Fragment>

                                <div className="h-100">
                                    <Spinner animation="border" role="status"
                                             style={{marginTop: "20%", marginLeft: "50%"}}>
                                    </Spinner>
                                </div>
                            </React.Fragment>

                            : this.affiche_trace()

                        }

                    </Card.Body>
                </Card>

            </Row>);
        }

    }

    menu() {
        const handleSelect = (eventKey) => this.setState({choix_menu: parseInt(eventKey), clicked_row: ''});
        return (<Nav variant="pills" defaultActiveKey={parseInt(this.state.choix_menu)} onSelect={handleSelect}>
            <Nav.Item>
                <Nav.Link eventKey="1"><AiOutlineHistory className="mr-1 mt-n1"/>Historique</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="2"><AiOutlineCarryOut className="mr-1 mt-n1"/>Programmation </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="3"><AiOutlineFileSearch className="mr-1 mt-n1"/>Trace</Nav.Link>
            </Nav.Item>
        </Nav>)
    }

    render() {


        return (<div>
            <body class="hold-transition sidebar-mini layout-fixed">
            <Nav_bar conso={this.state.conso}></Nav_bar>
            <Menu name={this.state.val}></Menu>
            <div class="content-wrapper">


                <section class="content">
                    <Card>
                        <Card.Header>
                            {this.menu()}
                        </Card.Header>
                        <Card.Body>
                            <Container fluid>
                                {this.tab_affichage()}
                            </Container>
                        </Card.Body>
                    </Card>
                </section>

            </div>
            </body>
        </div>);
    }
}

export default Accueil;
