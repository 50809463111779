import { BrowserRouter as Router, Route, Switch,Redirect,HashRouter } from "react-router-dom";
import Cookies from 'universal-cookie';
import Login1 from "./components/views/Login1";
import Menu from "./components/views/Menu";
import Nav_bar from "./components/views/Nav_bar";
import Table_test from "./components/views/Table_test";
import Configuration from "./components/views/Configuration";
import Accueil from "./components/Accueil";
import Deco from "./components/views/Deco";
import Test from "./components/views/Test";

 
 
const App = () => {
 
      
      
  return (<>
    
    <HashRouter hashType="noslash">
      <Switch>
        <Route path='/login' component={Login1} />
        <Route path='/accueil' component={Accueil}/>
        <Route path='/activite/prog' component={Accueil} />
        <Route path='/activite/trace/portable/:portable' component={Accueil} />
        <Route path='/activite/trace/rdv/:rdv' component={Accueil} />
        <Route path='/config' component={Configuration} />
        <Route path='/deconnexion' component={Deco} />
        <Route path='/' component={Login1} />
       
    
        
        
         
      </Switch>
     
    </HashRouter>
    </>
  );
}

export default App;
