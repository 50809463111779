import React from 'react';
import { Nav, Container, Row, Col, Tab, Table, Card, Button } from 'react-bootstrap';
import parse from 'html-react-parser';
import Ligne from './Ligne';


class Tableau extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      val: props.name,
      tabs: props.tab,
      res: [],
      colonne_tab: props.col,
      col: '',
      action: props.action,
      active: ''
    }
    this.handler = this.handler.bind(this);


  }
  handler(val, val1, index) {
    /*  this.props.handler(val,val1);
      console.log(val,val1);*/
    this.setState({ active: index })
    console.log("index recu par handeler");
    console.log(this.state.active);
  }
  couleur_ligne(index) {

  }
  colonne_tableau() {

    let colonne = "<tr>";
    for (let i = 0; i < this.props.col[0].length; i++) {
      colonne += "<th>" + this.props.col[0][i] + "</th>";
    }
    colonne += "</tr>";
    return parse(colonne);

  }
  ligne_tableau() {
    let rows = [];
    if (this.state.tabs.length > 0) {


      for (let i = 0; i < this.props.tab.length; i++) {

        rows.push(<Ligne active={this.state.active} col={this.props.col_num} type={this.props.type} handlers={this.handler} handler={this.props.handler} index_tab={i} data={this.props.tab[i]} />);

      }
    }
    return (
      rows
    );
  }
  componentWillReceiveProps(nextProps) {

    this.setState({ tabs: this.props.tab });

  }
  unset_active() {
    console.log("unset active");
    this.setState({ active: '' });
  }
  render() {


    return (
      <Card>
        <Table bordered hover>
          <thead>
            {this.colonne_tableau()}

          </thead>
          <tbody>
            {this.ligne_tableau()}

          </tbody>
        </Table></Card>);
  }
}
export default Tableau