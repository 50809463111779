import React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import parse from 'html-react-parser';
import Ligne from './Ligne';


class Test extends React.Component{
    constructor(props)
    {
      super(props);
     
     this.state = {
       
     
      
     
    }
    
     
}
     
    render()
    {
        const popoverRight = (
            <Popover id="popover-basic">
              <Popover.Title as="h3">Popover Right</Popover.Title>
              <Popover.Content>
                This is simple popover example right side.
              </Popover.Content>
            </Popover>
          ); 
           
          const popoverTop = (
            <Popover id="popover-basic">
              <Popover.Title as="h3">Popover Top</Popover.Title>
              <Popover.Content>
                This is simple popover example top side.
              </Popover.Content>
            </Popover>
          );
      
      return (<div className="container">
      <h1>React Bootstrap Popovers Example - ItSolutionStuff.com</h1>
 
      <OverlayTrigger trigger="click" placement="right" overlay={popoverRight}>
        <Button variant="success">Right - Click Me!</Button>
      </OverlayTrigger>
 
      <OverlayTrigger trigger="click" placement="top" overlay={popoverTop}>
        <Button variant="success">Top - Click Me!</Button>
      </OverlayTrigger>

  </div>
      );
    }
}
export default Test