import React from 'react';
import { Nav,Container,Row,Col,Tab,Table,Card,Button,Form,InputGroup } from 'react-bootstrap';
import parse from 'html-react-parser';
import Ligne from './Ligne';
import Tableau from './Tableau';
import axios from "axios";
import Cookies from 'universal-cookie';
import Token from '../../utilities/Token';
import { useTable, useSortBy, useFilters, useExpanded, usePagination } from 'react-table';
import Table_test from './Table_test';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
import moment from 'moment'

 


class Details2 extends React.Component{
    constructor(props)
    {
      super(props);
      console.log(props.data);
      console.log(props.index);
     this.state = {
         tab: [],
         tab1:[],
       val: props.val,
       id: props.id,
       index: props.index_tab,
       donne: '',
       columns: [],
       donnes: []
     }
     
    
    }
    componentWillReceiveProps(nextProps) {
      // You don't have to do this check first, but it can help prevent an unneeded render
     console.log(nextProps);
      
 
      if (nextProps.startTime !== this.state.startTime) {
        this.setState({ startTime: nextProps.startTime });
      }
    }
    checkbox(props)
    {
        if(props === 1)
        {
            if(this.props.data[this.props.index][0].queued1 === true)
            {
                return(<Form.Check
                required
                label=" "
                feedback=""
                feedbackType="invalid"
                checked
              />);
            }
            else
            {
                return(<Form.Check
                    required
                    label=" "
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />);
            }
        }
        else
        {
            if(this.props.data[this.props.index][0].queued2 === true)
            {
                return(<Form.Check
                required
                label=" "
                feedback="You must agree before submitting."
                feedbackType="invalid"
                checked
              />);
            }
            else
            {
                return(<Form.Check
                    required
                    label=" "
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />);
            }
        }
       
    }
    affichage()
    {
      
        if(this.props.index !== "" || this.props.data === undefined)
        {
            return(<Form>
                <fieldset disabled>
                <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Code patient :</Form.Label>
                      <Form.Control size="sm" type="text" placeholder={this.props.data[this.props.index][0].dmsId} required />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                      <Form.Label>Civilité patient :</Form.Label>
                      <Form.Control size="sm" type="text" placeholder={this.props.data[this.props.index][0].civilitePatient} required />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Nom patient :</Form.Label>
                      <Form.Control size="sm" type="text" placeholder={this.props.data[this.props.index][0].nomPatient} required />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                      <Form.Label>Prénom patient :</Form.Label>
                      <Form.Control size="sm" type="text" placeholder={this.props.data[this.props.index][0].prenomPatient} required />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Date de naissance :</Form.Label>
                      <Form.Control size="sm" type="text" placeholder={moment(this.props.data[this.props.index][0].dateNaissance).format('DD/MM/YYYY')} required />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                      <Form.Label>Portable :</Form.Label>
                      <Form.Control size="sm" type="text" placeholder={this.props.data[this.props.index][0].portable} required />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Nom parent :</Form.Label>
                      <Form.Control size="sm" type="text" placeholder={this.props.data[this.props.index][0].nomParent} required />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                      <Form.Label>Code praticien :</Form.Label>
                      <Form.Control size="sm" type="text" placeholder={this.props.data[this.props.index][0].codePraticien} required />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Nom praticien :</Form.Label>
                      <Form.Control size="sm" type="text" placeholder={this.props.data[this.props.index][0].nomPraticien} required />
                    </Form.Group>
                    
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Date envoi 1  :</Form.Label>
                      <Form.Control size="sm" type="text" placeholder={this.props.data[this.props.index][0].dateHeureEnvoiSms1} required />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                      <Form.Label>Envoi 1 :</Form.Label>
                     {this.checkbox(1)}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Date envoi 2 :</Form.Label>
                      <Form.Control size="sm" type="text" placeholder={this.props.data[this.props.index][0].dateHeureEnvoiSms2} required />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                      <Form.Label>Envoi 2 :</Form.Label>
                      {this.checkbox(2)}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Rendez-vous :</Form.Label>
                      <Form.Control size="sm" type="text" placeholder={this.props.data[this.props.index][0].rdv} required />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                      <Form.Label>ID Rendez-vous :</Form.Label>
                      <Form.Control size="sm" type="text" placeholder={this.props.data[this.props.index][0].rdvId} required />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Code type RDV :</Form.Label>
                      <Form.Control size="sm" type="text" placeholder={this.props.data[this.props.index][0].typeRdvCode} required />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                      <Form.Label>Nom type RDV :</Form.Label>
                      <Form.Control size="sm" type="text" placeholder={this.props.data[this.props.index][0].typeRdvNom} required />
                    </Form.Group>
                  </Row>
                   
                  
                   
               
                </fieldset>
              </Form>);
        }
    }
    render()
    {

       
        
   
        return (<div><Card>
          <Card.Header>Détails</Card.Header>
          <Card.Body>
          {this.affichage()}
          </Card.Body>
        </Card>
        
        </div>
        );
       
       
        
      }
   
       
    }

export default Details2