import React from 'react';
import {Nav, Container, Row, Col, Tab, Table, Card, Button, Form, Alert, Spinner} from 'react-bootstrap';
import parse from 'html-react-parser';
import Ligne from './Ligne';
import Tableau from './Tableau';
import axios from "axios";
import Cookies from 'universal-cookie';
import Token from '../../utilities/Token';
import {useTable, useSortBy, useFilters, useExpanded, usePagination} from 'react-table';
import Table_test from './Table_test';
import {ThemeConsumer} from 'react-bootstrap/esm/ThemeProvider';


class Details extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: [],
            tab1: [],
            tab_1: ["Nom patient", "Prénom patient", "Date de naissance", "Nom parent", "Portable", "Code type rdv", "Nom type rdv", "Rendez-vous", "Nom praticient", "Code message", "id rdv", "Cvilite parent", "Code Ortokhis", "Clé rdv"],
            val: props.val,
            id: props.id,
            index: props.index_tab,
            donne: '',
            columns: [],
            donnes: [],
            loading: ''
        }
        this.handler = this.handler.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        console.log(nextProps);
        if (nextProps.id && nextProps.type === "1") {
            this.get_detail(nextProps.id);

        }
    }

    get_detail(param) {
        if (param !== this.props.id) {
            var id = param;
        } else {
            var id = this.props.id;
        }

        const cookies = new Cookies();
        var time = new Date().toUTCString();
        /*  var cabinet = cookies.get('cabinet');
          var token = cookies.get('token');*/
        var id_cabinet = localStorage.getItem('id_cabinet');
        var cabinet = localStorage.getItem('cabinet');
        var token = localStorage.getItem('token');
        var tokens = Token.calcul_token("GET", null, token, cabinet, '/cabinet/file/' + id);


        // a modif
        let optionurl = '/cabinet/file/' + id;
        if (window.location.href.indexOf('localhost') > -1)

            var baseURL = 'http://localhost:8844' + optionurl;

        else if (window.location.href.indexOf('-dev') > -1)

            var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

        else

            var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;
        //const baseURL = "http://localhost:8844/cabinet/"+id_cabinet;
        //const baseURL = "http://localhost:8844/cabinet/file/"+this.props.id;


        const config = {
            headers: {
                'X-AUTH-TOKEN': tokens,
                'Date-request': time,
                'Content-type': 'application/json'
            },
            data: {}
        }
        this.setState({loading: true}, () => {
            axios.get(baseURL, config)
                .then((response) => {
                    this.setState({
                        loading: false
                    });
                    if (response.status == 200) {

                        var student = [];
                        Object.keys(response.data.data).forEach(key => {
                            //     ligne = {'nom_patient': response.data.data[key].nom_patient,'prenom_patient':response.data.data[key].prenom_patient,'date_naissance':response.data.data[key].date_naissance,'nom_parent':response.data.data[key].nom_parent,'portable':response.data.data[key].portable,'type_rdv_code':response.data.data[key].type_rdv_code,'type_rdv_nom':response.data.data[key].type_rdv_nom,'rdv':response.data.data[key].rdv,'code_praticien':response.data.data[key].code_praticien,'nom_praticien':response.data.data[key].nom_praticient,'code_message':response.data.data[key].code_message,'rdv_id':response.data.data[key].rdv_id,'civilite_patient':response.data.data[key].civilite_patient,'dms_id':response.data.data[key].dms_id,'cle_rdv':response.data.data[key].cle_rdv};
                            var obj = {
                                'nom_patient': response.data.data[key].nom_patient,
                                'prenom_patient': response.data.data[key].prenom_patient,
                                'date_naissance': response.data.data[key].date_naissance,
                                'nom_parent': response.data.data[key].nom_parent,
                                "portable": response.data.data[key].portable,
                                "type_rdv_code": response.data.data[key].type_rdv_code,
                                "type_rdv_nom": response.data.data[key].type_rdv_nom,
                                "rdv": response.data.data[key].rdv,
                                "code_praticien": response.data.data[key].code_praticien,
                                "nom_praticien": response.data.data[key].nom_praticien,
                                "code_message": response.data.data[key].code_message,
                                "rdv_id": response.data.data[key].rdv_id,
                                "civilite_patient": response.data.data[key].civilite_patient,
                                "dms_id": response.data.data[key].dms_id,
                                "cle_rdv": response.data.data[key].cle_rdv

                            }
                            student.push(obj)

                        });

                        this.setState({tab: student});


                    }
                })
                .catch(error => {

                });
        });


    }

    get_colonne() {
        const columns = [
            {
                Header: () => null,
                id: 'expander', // 'id' is required
                Cell: ({row}) => (
                    <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? '➖' : '➕'}
        </span>
                ),
            },
            {
                Header: 'Nom patient',
                accessor: 'nom_patient',
                disableSortBy: true,

                filter: 'equals',
            },
            {
                Header: 'Prénom patient',
                accessor: 'prenom_patient',
            },
            {
                Header: 'Date de naissance',
                accessor: 'date_naissance',
            },
            {
                Header: 'Portable',
                accessor: 'portable',
            },


        ];
        if (this.state.tab.length === 0) {
            return (<Alert variant={'warning'}>
                Pas de résultat disponible.
            </Alert>);
        }

        return (this.state.loading ? <div className='h-100'><Spinner animation="border" role="status"
                                                                     style={{marginTop: "10%", marginLeft: "50%"}}>
                <span className="visually-hidden">Loading...</span>

            </Spinner> <br/> <br/> <br/> <br/> <br/> <br/></div> :
            <Table_test columns={columns} data={this.state.tab} renderRowSubComponent={this.renderRowSubComponent}
                        handler={this.handler} pageLenght={4}/>);
    }

    renderRowSubComponent(row) {
        const {
            type_rdv_code,
            type_rdv_nom,
            rdv,
            code_praticien,
            nom_praticien,
            code_message,
            civilite_patient,
            dms_id,
            cle_rdv
        } = row.original;
        return (
            <Card style={{margin: '0 auto'}}>
                <Card.Body>
                    <Card.Title>

                    </Card.Title>
                    <Card.Text>
                        <strong>Code type rendez-vous</strong> : {type_rdv_code} <br/>
                        <strong>Nom type rendez-vous</strong> : {type_rdv_nom} <br/>
                        <strong>Rendez-vous</strong> : {rdv} <br/>
                        <strong>Code Praticien</strong> : {code_praticien} <br/>
                        <strong>Nom Praticien</strong> : {nom_praticien} <br/>
                        <strong>Code message</strong> : {code_message} <br/>
                        <strong>Civilite patient</strong> : {civilite_patient} <br/>
                        <strong>Code Orthokis</strong> : {dms_id} <br/>
                        <strong>Clé rendez-vous</strong> : {cle_rdv} <br/>
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }

    handler(val, val1) {
    }

    render() {


        if (this.props.val === '' || this.props.index === '' || this.props.index.length === 0) {

            return (<Card className="h-100">
                <Card.Header>Détails</Card.Header>
                <Card.Body>
                </Card.Body>
            </Card>)
        } else {
            if (this.props.type === "3") {
                return (<div><Card>
                        <Card.Header>Détails</Card.Header>
                        <Card.Body>
                            <Form>
                                <fieldset disabled>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledTextInput">Date de l'évènement :</Form.Label>
                                        <Form.Control id="disabledTextInput"
                                                      placeholder={this.props.data[this.props.index][4]}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledSelect">Date d'envoi :</Form.Label>
                                        <Form.Control id="disabledTextInput1"
                                                      placeholder={this.props.data[this.props.index][1]}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledSelect">Portable :</Form.Label>
                                        <Form.Control id="disabledTextInput1"
                                                      placeholder={this.props.data[this.props.index][3]}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledSelect">ID rdv :</Form.Label>
                                        <Form.Control id="disabledTextInput1"
                                                      placeholder={this.props.data[this.props.index][5]}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledSelect">ID SMS :</Form.Label>
                                        <Form.Control id="disabledTextInput1"
                                                      placeholder={this.props.data[this.props.index][6]}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledSelect">Statut :</Form.Label>
                                        <Form.Control id="disabledTextInput1"
                                                      placeholder={this.props.data[this.props.index][3]}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledSelect">Retour :</Form.Label>
                                        <Form.Control id="disabledTextInput1"
                                                      placeholder={this.props.data[this.props.index][2]}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Texte :</Form.Label>
                                        <Form.Control as="textarea" placeholder={this.props.data[this.props.index][7]}
                                                      rows={3}/>
                                    </Form.Group>


                                </fieldset>
                            </Form>
                        </Card.Body>
                    </Card>
                        <Card>


                        </Card>
                    </div>
                );
            } else if (this.props.type === "2") {
                return (<div><Card>
                        <Card.Header>Détails</Card.Header>
                        <Card.Body>
                            <Form>
                                <fieldset disabled>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledTextInput">Date de mise à jour :</Form.Label>
                                        <Form.Control id="disabledTextInput"
                                                      placeholder={this.props.data[this.props.index][0]}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledSelect">Emplacement :</Form.Label>
                                        <Form.Control id="disabledTextInput1"
                                                      placeholder={this.props.data[this.props.index][4]}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledSelect">Portable :</Form.Label>
                                        <Form.Control id="disabledTextInput1"
                                                      placeholder={this.props.data[this.props.index][3]}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledSelect">Id rdv :</Form.Label>
                                        <Form.Control id="disabledTextInput1"
                                                      placeholder={this.props.data[this.props.index][5]}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledSelect">Nouvelle date rdv :</Form.Label>
                                        <Form.Control id="disabledTextInput1"
                                                      placeholder={this.props.data[this.props.index][2]}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledSelect">Nouvel état rdv :</Form.Label>
                                        <Form.Control id="disabledTextInput1"
                                                      placeholder={this.props.data[this.props.index][6]}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Actions :</Form.Label>
                                        <Form.Control as="textarea" placeholder={this.props.data[this.props.index][7]}
                                                      rows={4}/>
                                    </Form.Group>


                                </fieldset>
                            </Form>
                        </Card.Body>
                    </Card>
                        <Card>


                        </Card>
                    </div>
                );
            } else {
                return (<div><Card>
                        <Card.Header>Détails</Card.Header>
                        <Card.Body>
                            <Form>
                                <fieldset disabled>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledTextInput">Date de réception :</Form.Label>
                                        <Form.Control id="disabledTextInput"
                                                      placeholder={this.props.data[this.props.index][0]}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledSelect">Nombre de lignes dans le fichier
                                            :</Form.Label>
                                        <Form.Control id="disabledTextInput1"
                                                      placeholder={this.props.data[this.props.index][2]}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="disabledSelect">Nombre de lignes créés :</Form.Label>
                                        <Form.Control id="disabledTextInput1"
                                                      placeholder={this.props.data[this.props.index][1]}/>
                                    </Form.Group>


                                </fieldset>
                            </Form>
                        </Card.Body>
                    </Card>
                        <Card className="h-100">

                            {this.get_colonne()}

                        </Card>
                    </div>
                );
            }

        }


    }
}

export default Details