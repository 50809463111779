import React from 'react';
import {withRouter} from "react-router-dom";
import {
    Nav,
    Container,
    Row,
    Col,
    Tab,
    Table,
    Card,
    Button,
    Form,
    FloatingLabel,
    FormControl,
    InputGroup,
    Modal
} from 'react-bootstrap';
import axios from 'axios';
import Token from '../../utilities/Token';
import Cookies from 'universal-cookie';
import parse from 'html-react-parser';
import Ligne from './Ligne';
import Nav_bar from './Nav_bar';
import Menu from './Menu';
import {AiOutlineHistory, AiOutlineCalendar, AiOutlineSearch} from "react-icons/ai";
import CounterInput from 'react-bootstrap-counter';
import TimePicker from 'react-bootstrap-time-picker';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Table_test from './Table_test';
import {AiFillDelete} from 'react-icons/ai';
import Detail_message from './Detail_message';
import Swal from 'sweetalert2';
import queryString from 'query-string';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';


class Configuration extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            choix_menu: '1',
            data: '',
            info_cabinet: '',
            expediteur: '',
            fuseau: '',
            delai_sms1: '',
            delai_sms2: '',
            heure_envoi1: '',
            heure_envoie1_sec: '',
            heure_envoi2: '',
            heure_envoie2_sec: '',
            heure_envoi_anniversaire: '',
            heure_envoi_anniversaire_sec: '',
            heure_envoi_autre: '',
            heure_envoi_autre_sec: '',
            anniversaire: '',
            anniversaire_message: '',
            id_message_anniversaire: '',
            messages: [],
            clicked_row: '',
            show: false,
            modal_code: '',
            modal_titre: '',
            modal_texte: '',
            modal_verif_code: '',
            active_sms2: '',
            conso: '',
            praticien: '',
            disabled: ''


        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChanges = this.handleChanges.bind(this);
        this.handler = this.handler.bind(this);
        this.tab_message_construct = this.tab_message_construct.bind(this);
        this.supprimer_message = this.supprimer_message.bind(this);
        this.handleValidModal = this.handleValidModal.bind(this);

    }

    handleChange(event, val) {


        if (event === "expediteur") {
            this.state.expediteur = val.target.value;

        } else if (event === "active_sms2") {
            this.setState({active_sms2: val.target.value})
        } else if (event === "delai_sms1") {
            this.state.delai_sms1 = val.target.value;

        } else if (event === "delai_sms2") {
            this.state.delai_sms2 = val.target.value;

        } else if (event === "time_sms1") {

            this.setState({heure_envoi1: moment().startOf('day').seconds(val).format('H:mm:ss')})
            this.state.heure_envoi1 = moment().startOf('day').seconds(val).format('HH:mm:ss');
            this.setState({heure_envoie1_sec: val})
            this.state.heure_envoie1_sec = val;
        } else if (event === "time_sms2") {
            this.setState({heure_envoi2: moment().startOf('day').seconds(val).format('H:mm:ss')})
            this.state.heure_envoi2 = moment().startOf('day').seconds(val).format('HH:mm:ss');
            this.setState({heure_envoie2_sec: val})
            this.state.heure_envoie2_sec = val;
        } else if (event === "time_sms_anniversaire") {
            this.setState({heure_envoi_anniversaire: moment().startOf('day').seconds(val).format('H:mm:ss')})
            this.state.heure_envoi_anniversaire = moment().startOf('day').seconds(val).format('HH:mm:ss');
            this.setState({heure_envoi_anniversaire_sec: val})
            this.state.heure_envoi_anniversaire_sec = val;
        } else if (event === "time_sms_autres") {
            this.setState({heure_envoi_autre: moment().startOf('day').seconds(val).format('H:mm:ss')})
            this.state.heure_envoi_autre = moment().startOf('day').seconds(val).format('HH:mm:ss');
            this.setState({heure_envoi_autre_sec: val})
            this.state.heure_envoi_autre_sec = val;
        }
        this.set_cabinet_config(event);
    }

    handleChanges(e, a) {


        if (e === "fuseau") {
            this.state.timezone = a.target.value;
            this.set_cabinet_config("timezone");
        } else {
            this.state.anniversaire_message = a.target.value;
            this.set_cabinet_config();
        }
    }

    get_cabinet_detail() {
        const cookies = new Cookies();
        var time = new Date().toUTCString();
        var id_cabinet = localStorage.getItem('id_cabinet');
        var cabinet = localStorage.getItem('cabinet');
        var token = localStorage.getItem('token');
        var tokens = Token.calcul_token("GET", null, token, cabinet, '/cabinet/' + id_cabinet);
        let optionurl = "/cabinet/" + id_cabinet;
        if (window.location.href.indexOf('localhost') > -1)

            var baseURL = 'http://localhost:8844' + optionurl;

        else if (window.location.href.indexOf('-dev') > -1)

            var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

        else

            var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;


        //  const baseURL = "http://localhost:8844/cabinet/"+id_cabinet;
        const config = {
            headers: {
                'X-AUTH-TOKEN': tokens,
                'Date-request': time,
                'Content-type': 'application/json'
            },
            data: {}
        }

        axios.get(baseURL, config)
            .then((response) => {
                let array = [];
                if (response.status == 200) {

                    if (response.data.heureEnvoi2 === null || response.data.heureEnvoi2 === "" || response.data.heureEnvoi2 === undefined) {
                        this.setState({active_sms2: false})
                    } else {
                        this.setState({active_sms2: true})
                    }

                    const secondsEnvoi1 = moment(response.data.heureEnvoi1).unix() + 3600;
                    const secondsEnvoi2 = moment(response.data.heureEnvoi2).unix() + 3600;
                    const secondsAnniv = moment(response.data.heureEnvoiAnniversaire).unix() + 3600;
                    const heureEnvoiAutres = moment(response.data.heureEnvoiAutres).unix() + 3600;

                    this.setState({
                        expediteur: response.data.sender,
                        fuseau: response.data.timezone,
                        delai_sms1: response.data.delaiSms1,
                        delai_sms2: response.data.delaiSms2,
                        heure_envoi1: secondsEnvoi1,
                        heure_envoi2: secondsEnvoi2,
                        heure_envoi_anniversaire: secondsAnniv,
                        heure_envoi_autre: heureEnvoiAutres
                    })

                    this.setState({
                        anniversaire: response.data.msgAnniversaire,
                        id_message_anniversaire: response.data.msgAnniversaire.id
                    })

                    if (response.data.timezone.indexOf("Paris") !== -1) {
                        this.setState({fuseau: "France"});
                    } else if (response.data.timezone.indexOf("Brussels") !== -1) {
                        this.setState({fuseau: "Belgique"});
                    } else if (response.data.timezone.indexOf("Guadeloupe") !== -1) {
                        this.setState({fuseau: "Guadeloupe"});
                    } else if (response.data.timezone.indexOf("Cayenne") !== -1) {
                        this.setState({fuseau: "Guyane"});
                    } else if (response.data.timezone.indexOf("Port-au-Prince") !== -1) {
                        this.setState({fuseau: "Haiti"});
                    } else if (response.data.timezone.indexOf("Luxembourg") !== -1) {
                        this.setState({fuseau: "Luxembourg"});
                    } else if (response.data.timezone.indexOf("Martinique") !== -1) {
                        this.setState({fuseau: "Martinique"});
                    } else if (response.data.timezone.indexOf("Reunion") !== -1) {
                        this.setState({fuseau: "Reunion"});
                    } else {
                        this.setState({fuseau: "Suisse"});
                    }
                }

            })
            .catch(error => {

            });
    }

    get_conso() {
        var time = new Date().toUTCString();
        var id_cabinet = localStorage.getItem('id_cabinet');
        var cabinet = localStorage.getItem('cabinet');
        var token = localStorage.getItem('token');
        var tokens = Token.calcul_token("GET", null, token, cabinet, '/cabinet/conso');
        let optionurl = '/cabinet/conso';
        if (window.location.href.indexOf('localhost') > -1)

            var baseURL = 'http://localhost:8844' + optionurl;

        else if (window.location.href.indexOf('-dev') > -1)

            var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

        else

            var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;

        const config = {
            headers: {
                'X-AUTH-TOKEN': tokens,
                'Date-request': time,
                'Content-type': 'application/json'
            },
            data: {}
        }


        axios.get(baseURL, config)
            .then((response) => {

                if (response.status === 200) {


                    localStorage.setItem('conso', JSON.stringify(response.data.data));
                    // a voire
                    this.setState({conso: response.data.data})
                    this.forceUpdate();


                } else {

                }
            })
            .catch(error => {

            });


    }

    get_message() {

        const cookies = new Cookies();
        var time = new Date().toUTCString();
        var id_cabinet = localStorage.getItem('id_cabinet');
        var cabinet = localStorage.getItem('cabinet');
        var token = localStorage.getItem('token');
        var tokens = Token.calcul_token("GET", null, token, cabinet, '/message/cabinet/' + id_cabinet);
        let optionurl = "/message/cabinet/" + id_cabinet;
        if (window.location.href.indexOf('localhost') > -1)

            var baseURL = 'http://localhost:8844' + optionurl;

        else if (window.location.href.indexOf('-dev') > -1)

            var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

        else

            var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;


        // const baseURL = "http://localhost:8844/message/cabinet/"+id_cabinet;
        const config = {
            headers: {
                'X-AUTH-TOKEN': tokens,
                'Date-request': time,
                'Content-type': 'application/json'
            },
            data: {}
        }

        axios.get(baseURL, config)
            .then((response) => {


                let array = [];
                let array_msg = [];
                if (response.status == 200) {
                    let i = 0;
                    Object.keys(response.data).forEach(key => {
                        if (!localStorage.getItem("praticien")) {
                            localStorage.setItem("praticien", response.data[key].cabinet.nom);
                        }

                        response.data[key].index = i;
                        array.push(response.data[key]);
                        array_msg.push([response.data[key].id, response.data[key].title, response.data[key].codeMessage, response.data[key].texte, i]);
                        i++;

                    });

                    array.sort((a, b) => a.codeMessage > b.codeMessage ? 1 : -1);
                    this.setState({data: array, messages: array_msg}, function () {

                        this.forceUpdate();

                    });


                }

            })
            .catch(error => {

            });
    }

    set_cabinet_config(params) {
        var time = new Date().toUTCString();
        var id_cabinet = localStorage.getItem('id_cabinet');
        var cabinet = localStorage.getItem('cabinet');
        var token = localStorage.getItem('token');

        if (params === "expediteur") {
            var params = {
                sender: this.state.expediteur,
                id: id_cabinet
            };
        } else if (params === "active_sms2") {
            if (!this.state.active_sms2) {
                var params = {
                    heure_envoi2: moment().format("YYYY-MM-DD").toString() + "T01:00:00+01:00",
                    id: id_cabinet
                };
            } else {
                var params = {
                    heure_envoi2: null,
                    id: id_cabinet
                };
            }

        } else if (params === "timezone") {
            var params = {
                timezone: this.state.timezone,
                id: id_cabinet
            };
        } else if (params === "delai_sms1") {
            var params = {
                delai_sms1: this.state.delai_sms1,
                id: id_cabinet
            };
        } else if (params === "delai_sms2") {
            var params = {
                delai_sms2: this.state.delai_sms2,
                id: id_cabinet
            };
        } else if (params === "time_sms1") {


            var params = {
                heure_envoi1: moment().format("YYYY-MM-DD").toString() + "T" + this.state.heure_envoi1 + "+01:00",
                id: id_cabinet
            };

        } else if (params === "time_sms2") {
            var params = {
                heure_envoi2: moment().format("YYYY-MM-DD").toString() + "T" + this.state.heure_envoi2 + "+01:00",
                id: id_cabinet
            };

        } else if (params === "time_sms_anniversaire") {
            var params = {
                heure_envoi_anniversaire: moment().format("YYYY-MM-DD").toString() + "T" + this.state.heure_envoi_anniversaire + "+01:00",
                id: id_cabinet
            };
        } else if (params === "time_sms_autres") {
            var params = {
                heure_envoi_autres: moment().format("YYYY-MM-DD").toString() + "T" + this.state.heure_envoi_autre + "+01:00",
                id: id_cabinet
            };
        } else {
            // ici a modif

            if (this.state.anniversaire_message === "Non définie") {
                var params = {
                    msg_anniversaire: null,
                    id: id_cabinet
                }
            } else {
                var params = {
                    msg_anniversaire: {
                        code_message: this.state.messages[this.state.anniversaire_message][2],
                        id: this.state.messages[this.state.anniversaire_message][0],
                        texte: this.state.messages[this.state.anniversaire_message][3],
                        title: this.state.messages[this.state.anniversaire_message][1],
                    },
                    id: id_cabinet
                }
            }

        }
        var tokens = Token.calcul_token("PUT", params, token, cabinet, '/cabinet/' + id_cabinet);

        let optionurl = "/cabinet/" + id_cabinet;
        if (window.location.href.indexOf('localhost') > -1)

            var baseURL = 'http://localhost:8844' + optionurl;

        else if (window.location.href.indexOf('-dev') > -1)

            var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

        else

            var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;


        //    const baseURL = "http://localhost:8844/cabinet/"+id_cabinet;

        axios.put(baseURL, params,
            {
                headers: {
                    'X-AUTH-TOKEN': tokens,
                    'Date-request': time,
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {

                toast.success('Modification effectuée avec succès', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            })
            .catch(error => {

                toast.error('Problème de connexion', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });


    }

    list_message() {
        let list = [];
        list.push(<option>{this.state.anniversaire.title}</option>);
        list.push(<option value={"Non définie"}>Non définie</option>);
        Object.keys(this.state.messages).forEach(key => {
            list.push(<option value={this.state.messages[key][4]}>{this.state.messages[key][1]}</option>)
        });
        return (<Form.Select aria-label="Default select example" onChange={this.handleChanges.bind(this, "message")}>
            {list}
        </Form.Select>);
    }

    componentDidMount() {
        const value = queryString.parse(this.props.location.search);

        const cookies = new Cookies();
        if (value.cle) {

            this.get_cle_info(value.cle);


        } else if (localStorage.getItem('token') === null) {

            this.props.history.push("/login");
        } else {
            this.get_message();
            this.get_cabinet_detail();
            this.get_conso();
        }


    }

    get_cle_info(cle) {
        const cookies = new Cookies();
        let optionurl = "/cabinet/key/connect/" + cle;
        if (window.location.href.indexOf('localhost') > -1)

            var baseURL = 'http://localhost:8844' + optionurl;

        else if (window.location.href.indexOf('-dev') > -1)

            var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

        else

            var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;


        axios.get(baseURL, {})
            .then((response) => {


                let array = [];
                let array_msg = [];
                if (response.status == 200) {


                    cookies.set('cabinet', response.data.serie, {path: '/'});
                    cookies.set('id_cabinet', response.data.id, {path: '/'});
                    cookies.set('token', response.data.token, {path: '/'});
                    // set item
                    localStorage.setItem('cabinet', response.data.serie);
                    localStorage.setItem('id_cabinet', response.data.id);
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('serie', response.data.serie);
                    this.get_message();
                    this.get_cabinet_detail();
                    this.get_conso();
                    this.props.history.push("/config");
                }

            })
            .catch(error => {
                this.props.history.push("/login");
            });


    }

    handler(val, val1) {

        /* this.setState({ clicked_row: val });
         this.state.clicked_row = val;*/
        if (val1 !== undefined && val1.target.nodeName !== "TD") {
            let id = val1.target.id;
            let id_message = this.state.data[id].id;
            this.setState({clicked_row: ''}, function () {
                this.supprimer_message(id_message);
            });


        }
        this.setState({clicked_row: val}, function () {

            this.forceUpdate();
        });

        /* this.state.clicked_row = val1;
         console.log(this.state.clicked_row);*/
    }

    supprimer_message(id_message) {
        if (id_message === this.state.id_message_anniversaire) {
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Vous ne pouvez pas supprimer le modèle utilisé pour le message d\'anniversaire',

            })
        } else {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Êtes-vous sûr de vouloir supprimer le message ?',
                text: "Attention, cette action est irréversible",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirmer',
                cancelButtonText: 'Annuler',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    const cookies = new Cookies();
                    var time = new Date().toUTCString();
                    var id_cabinet = localStorage.getItem('id_cabinet');
                    var cabinet = localStorage.getItem('cabinet');
                    var token = localStorage.getItem('token');
                    // a changer
                    var params = {

                        id: id_message
                    };
                    var tokens = Token.calcul_token("DELETE", params, token, cabinet, '/message/' + id_message);

                    let optionurl = "/message/" + id_message;
                    if (window.location.href.indexOf('localhost') > -1)

                        var baseURL = 'http://localhost:8844' + optionurl;

                    else if (window.location.href.indexOf('-dev') > -1)

                        var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

                    else

                        var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;


                    //   const baseURL = "http://localhost:8844/message/"+id_message;
                    const config = {
                        headers: {
                            'X-AUTH-TOKEN': tokens,
                            'Date-request': time,
                            'Content-type': 'application/json'
                        },
                        data: {
                            id: id_message
                        }
                    }

                    axios.delete(baseURL, config)
                        .then((response) => {


                            let array = [];
                            let array_msg = [];
                            if (response.status == 202) {
                                this.setState({clicked_row: ''})
                                this.get_message();
                                swalWithBootstrapButtons.fire(
                                    'Supprimer',
                                    '',
                                    'success'
                                )
                                this.setState({clicked_row: ''});
                                this.forceUpdate();
                            }

                        })
                        .catch(error => {

                        });

                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {

                }
            })
        }


    }

    tableau_message() {
        const columns = [

            {
                Header: 'Code',
                accessor: 'codeMessage',
                disableSortBy: false,


            },
            {
                Header: 'Titre',
                accessor: 'title',

            },
            {

                accessor: "index",

                Cell: ({value, row}) => {

                    // return row.original.id;
                    return (
                        <button class="btn btn-danger btn-sm rounded-0 red-text" type="button" id={row.original.index}
                                data-toggle="tooltip" data-placement="top" title="Delete"><span id={row.original.index}
                                                                                                style={{color: "white"}}><i
                            id={row.original.index} class="fa fa-trash"></i></span></button>)
                }
            },


        ];

        return (<Table_test columns={columns} data={this.state.data} handler={this.handler} pageLenght={13}/>);
    }

    tab_message_construct() {

        this.get_message();

        /*  this.forceUpdate();*/


    }

    handleChangeModal(event) {
        var code_mes = 1;
        if (this.state.data.length > 0) {
            code_mes = this.state.data[this.state.data.length - 1].codeMessage + 1;
        }

        if (event.target.value === "Vierge") {
            // 804
            this.setState({modal_code: code_mes, modal_texte: '', modal_titre: 'Vierge', disabled: ''});
        } else if (event.target.value === "") {
            this.setState({modal_code: '', modal_texte: '', modal_titre: '', disabled: 'disabled'});
        } else if (event.target.value === "Message 1er RDV") {
            this.setState({
                modal_code: code_mes,
                modal_texte: 'Cabinet d\'orthodontie du Dr [NOM_DOCTEUR] : Bonjour, nous vous confirmons avec plaisir votre premier RDV le [DATE_ET_HEURE_RDV].',
                modal_titre: 'Message 1er RDV',
                disabled: ''
            });
        } else if (event.target.value === "Message Bilan") {
            this.setState({
                modal_code: code_mes,
                modal_texte: 'Cabinet du Dr [NOM_DOCTEUR] : Bonjour, nous nous permettons de vous rappeler votre RDV du [DATE_ET_HEURE_RDV] afin de vous presenter les resultats des examens orthodontiques.',
                modal_titre: 'Message Bilan',
                disabled: ''
            });
        } else if (event.target.value === "Message News INVISAGN Enfant jusqu'à 24 ans(TU)") {
            this.setState({
                modal_code: code_mes,
                modal_texte: 'Bonjour [PRENOM_PATIENT] et bienvenue dans le Club des patients INVISALIGN ! Je tiens a t’encourager et a te soutenir pour ton traitement. Cordialement, Dr [NOM_DOCTEUR]',
                modal_titre: 'Message News INVISALIGN Enfant jusqu\'à 24 ans (TU)',
                disabled: ''
            });
        } else if (event.target.value === "Message News INVISAGN Adulte") {
            this.setState({
                modal_code: code_mes,
                modal_texte: 'Bonjour [PRENOM_PATIENT] et bienvenue dans le Club des patients INVISALIGN ! Je tiens a vous encourager et a vous soutenir pour votre traitement. Cordialement, Dr [NOM_DOCTEUR]',
                modal_titre: 'Message News INVISALIGN Adulte',
                disabled: ''
            });
        } else if (event.target.value === "Message Elastique") {
            this.setState({
                modal_code: code_mes,
                modal_texte: 'Bonjour, je compte sur toi pour bien porter tes elastiques selon mes indications. Si tu as besoin, telephones nous. + tu les porteras, + ça sera court. Bon courage',
                modal_titre: 'Message Elastique',
                disabled: ''
            });
        } else if (event.target.value === "Message Anniversaire") {
            this.setState({
                modal_code: code_mes,
                modal_texte: 'Et oui, nous aussi on y pense …. Alors… JOYEUX ANNIVERSAIRE !!! Le Cabinet du Dr [NOM_DOCTEUR]',
                modal_titre: 'Message Anniversaire',
                disabled: ''
            });
        } else if (event.target.value === "Message RDV Dépose") {
            this.setState({
                modal_code: code_mes,
                modal_texte: 'Cabinet du Dr [NOM_DOCTEUR] : Bonjour, demain, apres votre RDV, la vie sera encore plus belle :-D',
                modal_titre: 'Message RDV Dépose',
                disabled: ''
            });
        } else if (event.target.value === "Message News Classique") {
            this.setState({
                modal_code: code_mes,
                modal_texte: 'Cabinet du Dr [NOM_DOCTEUR] : Bonjour, nous esperons que tu t es bien habitue a ton appareil (sans trop de peine). Ca ira mieux dans quelques jours. Bon courage! On pense a toi',
                modal_titre: 'Message News Classique',
                disabled: ''
            });
        } else if (event.target.value === "Message Dépose") {
            this.setState({
                modal_code: code_mes,
                modal_texte: 'Cabinet du Dr [NOM_DOCTEUR] : Bonjour M [NOM_PATIENT] a RDV le [DATE_ET_HEURE_RDV]. N\'oubliez pas de solder votre compte le jour du debaguage! Demain vous sourirez a pleines dents :-D',
                modal_titre: 'Message Dépose',
                disabled: ''
            });
        } else if (event.target.value === "Message Recontact") {
            this.setState({
                modal_code: code_mes,
                modal_texte: 'Cabinet du Dr [NOM_DOCTEUR]: Bonjour, lors de notre dernier RDV, ns avions convenu de ns revoir le mois prochain pour [PRENOM_ENFANT]. Merci de tel afin de fixer un RDV a votre convenance.',
                modal_titre: 'Message Recontact',
                disabled: ''
            });
        } else if (event.target.value === "Message Absent") {
            this.setState({
                modal_code: code_mes,
                modal_texte: 'Cab du Dr [NOM_DOCTEUR] : Bonjour, pouvez-vous ns recontacter afin de refixer le RDV de [PRENOM_PATIENT] que vous avez manque, prevu initialement cette semaine ? Merci et a bientot !',
                modal_titre: 'Message Absent',
                disabled: ''
            });
        }
    }

    create_message() {
        const cookies = new Cookies();
        var time = new Date().toUTCString();
        var id_cabinet = localStorage.getItem('id_cabinet');
        var cabinet = localStorage.getItem('cabinet');
        var token = localStorage.getItem('token');
        var params = {

            code_message: this.state.modal_code,
            id: "Message-1",
            texte: this.state.modal_texte,
            title: this.state.modal_titre
        };
        var tokens = Token.calcul_token("POST", params, token, cabinet, '/message/');

        let optionurl = "/message/";
        if (window.location.href.indexOf('localhost') > -1)

            var baseURL = 'http://localhost:8844' + optionurl;

        else if (window.location.href.indexOf('-dev') > -1)

            var baseURL = window.location.protocol + '//api-sms-dev.arakis.fr' + optionurl;

        else

            var baseURL = window.location.protocol + '//api-sms.arakis.fr' + optionurl;


        //  const baseURL = "http://localhost:8844/message/";
        const config = {
            headers: {
                'X-AUTH-TOKEN': tokens,
                'Date-request': time,
                'Content-type': 'application/json'
            },
            data: {
                code_message: this.state.modal_code,
                id: "Message-1",
                texte: this.state.modal_texte,
                title: this.state.modal_titre
            }
        }

        axios.post(baseURL, {
                code_message: this.state.modal_code,
                id: "Message-1",
                texte: this.state.modal_texte,
                title: this.state.modal_titre
            },
            {
                headers: {
                    'X-AUTH-TOKEN': tokens,
                    'Date-request': time,
                    'Content-type': 'application/json'
                }
            })
            .then((response) => {


                let array = [];
                let array_msg = [];
                if (response.status == 200) {

                    this.forceUpdate();
                    this.get_message();
                    this.setState({show: false, modal_code: '', modal_texte: '', modal_titre: ''});
                    toast.success('Modification effectuée avec succès', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });


                }

            })
            .catch(error => {
                toast.error('Problème de connexion', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }


    handleValidModal() {

        this.create_message();


    }

    handleShow() {
        this.setState({show: true});
    }

    handleClose() {
        this.setState({show: false});
    }

    validate_code(event) {
        let array = [];

        Object.keys(this.state.data).forEach(key => {

            array.push(this.state.data[key].code_message);


        });

        if (array.indexOf(parseInt(event.target.value)) !== -1) {
            this.setState({modal_verif_code: "form-control is-invalid"})
            this.setState({modal_code: event.target.value})
        } else {
            this.setState({modal_verif_code: "form-control is-valid"})
            this.setState({modal_code: event.target.value})
        }


    }

    modal_valider() {
        if (this.state.modal_code === "" || this.state.modal_titre === "") {
            return "disabled";
        }
    }

    rappel_sms() {
        if (this.state.active_sms2) {

            return (<Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom03">

                    <Form.Label>Rappels SMS N°2 :</Form.Label>
                    <InputGroup className="mb-3">

                        <input className='form-control' id="delai_sms2" defaultValue={this.state.delai_sms2}
                               onChange={this.handleChange.bind(this, "delai_sms2")} type="number"></input>
                        <InputGroup.Text id="basic-addon2">Délais (jours)</InputGroup.Text>
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                    <Form.Label> </Form.Label>
                    <InputGroup className="mb-3">
                        <TimePicker classname='form-control-sm' value={this.state.heure_envoi2} format={24} step={15}
                                    onChange={this.handleChange.bind(this, "time_sms2")}/>
                        <InputGroup.Text id="basic-addon2">Heure</InputGroup.Text>
                    </InputGroup>
                </Form.Group>
            </Row>);
        } else {
            return ("");
        }
    }

    render() {
        const menu = this.state.choix_menu;

        const cookies = new Cookies();
        const handleClose = () => this.setState({
            show: false,
            modal_texte: '',
            modal_titre: '',
            modal_code: '',
            disabled: ''
        });
        var code_mes = 1;
        if (this.state.data.length == 0) {
            var handleShow1 = () => this.setState({modal_code: code_mes, modal_texte: '', modal_titre: 'Vierge'});
        } else {
            code_mes = this.state.data[this.state.data.length - 1].codeMessage + 1;
            var handleShow1 = () => this.setState({modal_code: code_mes, modal_texte: '', modal_titre: 'Vierge'});
        }
        const handleShow = () => this.setState({show: true});
        const valider = () => this.handleValidModal();

        const handleSelect = (eventKey) => this.setState({choix_menu: eventKey, clicked_row: ''});
        if (this.state.data !== "") {
            return (
                <div>
                    <body class="hold-transition sidebar-mini layout-fixed">
                    <Nav_bar conso={this.state.conso}></Nav_bar>
                    <Menu name={localStorage.getItem('praticien')}></Menu>
                    <div class="content-wrapper">


                        <section class="content">
                            <Card>
                                <Card.Header>
                                    <Nav variant="pills" defaultActiveKey={this.state.choix_menu}
                                         onSelect={handleSelect}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="1">Paramétrage du cabinet</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="2">Paramétrage SMS</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Card.Header>
                                <Card.Body>

                                    {menu == 1 &&
                                        <Container fluid>
                                            <Card>
                                                <Card.Header>Configuration Cabinet</Card.Header>
                                                <Card.Body>
                                                    <Form>
                                                        <Form.Group className="mb-3" controlId="expediteur">
                                                            <Form.Label>Expéditeur :</Form.Label>
                                                            <Form.Control type="text" maxLength="11"
                                                                          defaultValue={this.state.expediteur}
                                                                          onBlur={this.handleChange.bind(this, "expediteur")}/>
                                                        </Form.Group>

                                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                                            <Form.Label>Fuseau :</Form.Label>
                                                            <Form.Select aria-label="Default select example"
                                                                         onChange={this.handleChanges.bind(this, "fuseau")}>
                                                                <option>{this.state.fuseau}</option>
                                                                <option value="Europe/Brussels">Belgique (+32)</option>
                                                                <option value="Europe/Paris">France (+33)</option>
                                                                <option value="America/Guadeloupe">Guadeloupe (+590)</option>
                                                                <option value="America/Cayenne">Guyane (+594)</option>
                                                                <option value="America/Port-au-Prince">Haiti (+509)</option>
                                                                <option value="Europe/Luxembourg">Luxembourg (+352)</option>
                                                                <option value="America/Martinique">Martinique (+596)</option>
                                                                <option value="Indian/Reunion">Réunion (+262)</option>
                                                                <option value="Europe/Zurich">Suisse (+41)</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">

                                                        </Form.Group>
                                                        <ToastContainer/>
                                                    </Form>
                                                </Card.Body>
                                            </Card>
                                            <Card>
                                                <Card.Header>Rappels de Rendez-vous</Card.Header>
                                                <Card.Body>
                                                    <Form>

                                                        <Row className="mb-3">
                                                            <Form.Group as={Col} md="5" controlId="sms1">

                                                                <Form.Label>Rappels SMS N°1 :</Form.Label>
                                                                <InputGroup className="mb-3">

                                                                    <input className='form-control' id="delai_sms1"
                                                                           defaultValue={this.state.delai_sms1}
                                                                           onChange={this.handleChange.bind(this, "delai_sms1")}
                                                                           type="number"></input>
                                                                    <InputGroup.Text id="basic-addon2">Délais
                                                                        (jours)</InputGroup.Text>
                                                                </InputGroup>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="5" controlId="validationCustom04">
                                                                <Form.Label> </Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    <TimePicker id="time_sms1"
                                                                                classname='form-control-sm'
                                                                                value={this.state.heure_envoi1}
                                                                                format={24} step={15}
                                                                                onChange={this.handleChange.bind(this, "time_sms1")}/>
                                                                    <InputGroup.Text
                                                                        id="basic-addon2">Heure</InputGroup.Text>
                                                                </InputGroup>
                                                            </Form.Group>
                                                            <Form.Group as={Col} controlId="validationCustom04">
                                                                <Form.Label>Rappels SMS N°2 : </Form.Label>
                                                                <BootstrapSwitchButton
                                                                    onstyle="primary" offstyle="outline-danger"
                                                                    style="border"
                                                                    checked={this.state.active_sms2}
                                                                    width={120}
                                                                    onlabel='Désactiver'
                                                                    offlabel='Activer'
                                                                    onChange={(checked) => {
                                                                        this.setState({active_sms2: checked});
                                                                        this.set_cabinet_config("active_sms2");

                                                                    }}
                                                                />

                                                            </Form.Group>

                                                        </Row>
                                                        {this.rappel_sms()}

                                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">

                                                        </Form.Group>

                                                    </Form>
                                                </Card.Body>
                                            </Card>
                                            <Row>
                                                <Col> <Card>
                                                    <Card.Header>Anniversaires</Card.Header>
                                                    <Card.Body>
                                                        <Form>
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label>Heure :</Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    <TimePicker classname='form-control-sm'
                                                                                value={this.state.heure_envoi_anniversaire}
                                                                                onChange={this.handleChange.bind(this, "time_sms_anniversaire")}
                                                                                format={24} step={15}/>
                                                                    <InputGroup.Text
                                                                        id="basic-addon2">Heure</InputGroup.Text>
                                                                </InputGroup>
                                                            </Form.Group>

                                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                                <Form.Label>Message :</Form.Label>
                                                                {this.list_message()}
                                                            </Form.Group>
                                                            <Form.Group className="mb-3" controlId="formBasicCheckbox">

                                                            </Form.Group>

                                                        </Form>
                                                    </Card.Body>
                                                </Card></Col>
                                                <Col> <Card>
                                                    <Card.Header>Autres SMS</Card.Header>
                                                    <Card.Body>
                                                        <Form>
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label>Heure :</Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    <TimePicker classname='form-control-sm'
                                                                                value={this.state.heure_envoi_autre}
                                                                                onChange={this.handleChange.bind(this, "time_sms_autres")}
                                                                                format={24} step={15}/>
                                                                    <InputGroup.Text
                                                                        id="basic-addon2">Heure</InputGroup.Text>
                                                                </InputGroup>
                                                            </Form.Group>


                                                        </Form>
                                                    </Card.Body>
                                                </Card></Col>
                                            </Row>
                                        </Container>
                                    }
                                    {menu == 2 &&
                                        <Container fluid>
                                            <Row>
                                                <Col><Card>
                                                    <Card.Header>
                                                        <Container>
                                                            <Row>
                                                                <Col>Textes SMS</Col>
                                                                <Col lg={3}> <Button size="sm" onClick={handleShow}
                                                                                     variant="primary">Ajouter
                                                                    Modèle</Button>{' '}</Col>


                                                                <Modal show={this.state.show} onHide={handleClose}
                                                                       onShow={handleShow1}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>Ajout modèle SMS</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <Form.Group className="mb-3"
                                                                                    controlId="formBasicPassword">
                                                                            <Form.Label>Modèle SMS</Form.Label>
                                                                            <Form.Select
                                                                                aria-label="Default select example"
                                                                                onChange={this.handleChangeModal.bind(this)}>
                                                                                <option value="Vierge">Vierge</option>
                                                                                <option value="Message 1er RDV">Message
                                                                                    1er RDV
                                                                                </option>
                                                                                <option value="Message Bilan">Message
                                                                                    Bilan
                                                                                </option>
                                                                                <option
                                                                                    value="Message News INVISAGN Enfant jusqu'à 24 ans(TU)">Message
                                                                                    News INVISALIGN Enfant jusqu'à 24
                                                                                    ans (TU)
                                                                                </option>
                                                                                <option
                                                                                    value="Message News INVISAGN Adulte">Message
                                                                                    News INVISALIGN Adulte
                                                                                </option>
                                                                                <option
                                                                                    value="Message Elastique">Message
                                                                                    Elastique
                                                                                </option>
                                                                                <option
                                                                                    value="Message Anniversaire">Message
                                                                                    Anniversaire
                                                                                </option>
                                                                                <option
                                                                                    value="Message RDV Dépose">Message
                                                                                    RDV Dépose
                                                                                </option>
                                                                                <option value="Message Dépose">Message
                                                                                    Dépose
                                                                                </option>
                                                                                <option
                                                                                    value="Message News Classique">Message
                                                                                    News Classique
                                                                                </option>
                                                                                <option
                                                                                    value="Message Recontact">Message
                                                                                    Recontact
                                                                                </option>
                                                                                <option value="Message Absent">Message
                                                                                    Absent
                                                                                </option>
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3"
                                                                                    controlId="formBasicEmail">
                                                                            <Form.Label>Code SMS</Form.Label>
                                                                            <Form.Control type="number" min={0}
                                                                                          className={this.state.modal_verif_code}
                                                                                          value={this.state.modal_code}
                                                                                          onChange={e => this.validate_code(e)}
                                                                                          disabled={this.state.disabled}/>
                                                                            <Form.Control.Feedback type="invalid">Ce
                                                                                code existe déjà, merci de choisir un
                                                                                code non
                                                                                utilisé.</Form.Control.Feedback>
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3"
                                                                                    controlId="formBasicEmail">
                                                                            <Form.Label>Nom modèle</Form.Label>
                                                                            <Form.Control type="text" maxLength="50"
                                                                                          defaultValue={this.state.modal_titre}
                                                                                          onChange={e => this.setState({modal_titre: e.target.value})}
                                                                                          disabled={this.state.disabled}/>
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3"
                                                                                    controlId="formBasicEmail">
                                                                            <Form.Label>Texte SMS</Form.Label>
                                                                            <Form.Control as="textarea" rows={5}
                                                                                          value={this.state.modal_texte}
                                                                                          onChange={e => this.setState({modal_texte: e.target.value})}
                                                                                          disabled/>
                                                                        </Form.Group>


                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="danger" onClick={handleClose}
                                                                                style={{color: "white"}}>
                                                                            Annuler
                                                                        </Button>
                                                                        <Button variant="success" onClick={valider}
                                                                                disabled={this.modal_valider()}
                                                                                style={{color: "white"}}>
                                                                            Valider
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </Row>
                                                        </Container>


                                                    </Card.Header>
                                                    <Card.Body>
                                                        {this.tableau_message()}
                                                    </Card.Body>
                                                </Card></Col>
                                                {<Detail_message msg={this.tab_message_construct}
                                                                 index={this.state.clicked_row}
                                                                 data={this.state.data}/>}
                                            </Row>
                                        </Container>
                                    }

                                </Card.Body>
                            </Card>
                        </section>

                    </div>
                    </body>
                </div>);
        } else {
            return (<div>
                <body class="hold-transition sidebar-mini layout-fixed">
                <Nav_bar></Nav_bar>
                <Menu name={cookies.get('praticien')}></Menu>
                <div class="content-wrapper">


                    <section class="content">
                        <Card>
                            <Card.Header>
                                <Nav variant="pills" defaultActiveKey={this.state.choix_menu} onSelect={handleSelect}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="1">Paramétrage du cabinet</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="2">Paramétrage SMS</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Card.Header>
                            <Card.Body>
                                <Container fluid>
                                    <Card>
                                        <Card.Header>Configuration Cabinet</Card.Header>
                                        <Card.Body>
                                            <Form>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Expéditeur:</Form.Label>
                                                    <Form.Control type="text" placeholder=""/>
                                                </Form.Group> {this.state.data[0]}

                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Label>Fuseau:</Form.Label>
                                                    <Form.Select aria-label="Default select example">
                                                        <option>Open this select menu</option>
                                                        <option value="1">France</option>
                                                        <option value="2">Belgique</option>
                                                        <option value="3">Guadeloupe</option>
                                                        <option value="4">Guyane</option>
                                                        <option value="5">Haiti</option>
                                                        <option value="6">Luxembourg</option>
                                                        <option value="7">Martinique</option>
                                                        <option value="8">Réunion</option>
                                                        <option value="9">Suisse</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="formBasicCheckbox">

                                                </Form.Group>

                                            </Form>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Header>Rappels de Rendez-vous</Card.Header>
                                        <Card.Body>
                                            <Form>

                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                                                        <Form.Label>SMS N°1:</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder='' required/>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="3" controlId="validationCustom04">
                                                        <Form.Label>Nom type RDV:</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder="" required/>
                                                    </Form.Group>

                                                </Row>

                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                                                        <Form.Label>SMS N°2:</Form.Label>
                                                        <FloatingLabel controlId="floatingPassword" label="Password">
                                                            <Form.Control size="sm" type="text" placeholder=''
                                                                          required/>
                                                        </FloatingLabel>

                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                                                        <Form.Label>Nom type RDV:</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder="" required/>
                                                    </Form.Group>
                                                </Row>
                                                <Form.Group className="mb-3" controlId="formBasicCheckbox">

                                                </Form.Group>

                                            </Form>
                                        </Card.Body>
                                    </Card>
                                    <Row>
                                        <Col> <Card>
                                            <Card.Header>Anniversaires</Card.Header>
                                            <Card.Body>
                                                <Form>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Expéditeur:</Form.Label>
                                                        <Form.Control type="email" placeholder=""/>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Label>Fuseau:</Form.Label>
                                                        <Form.Control type="password" placeholder=""/>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicCheckbox">

                                                    </Form.Group>

                                                </Form>
                                            </Card.Body>
                                        </Card></Col>
                                        <Col> <Card>
                                            <Card.Header>Autres SMS</Card.Header>
                                            <Card.Body>
                                                <Form>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Expéditeur:</Form.Label>
                                                        <Form.Control type="email" placeholder=""/>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Label>Fuseau:</Form.Label>
                                                        <Form.Control type="password" placeholder=""/>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicCheckbox">

                                                    </Form.Group>

                                                </Form>
                                            </Card.Body>
                                        </Card></Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </section>

                </div>
                </body>
            </div>);
        }

    }
}

export default withRouter(Configuration)