import React from "react";
import { withRouter,Redirect  } from "react-router-dom";
import Cookies from 'universal-cookie';
import Login1 from "./Login1";



class Deco extends React.Component {
  constructor(props)
{
     super(props);
     
}
  componentDidMount()
  {
     
      localStorage.clear();
         

      
  }
  render()
  {
    return(<Redirect to="/Login" />);
  }
   
   
}
export default withRouter(Deco);